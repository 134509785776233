import React from "react";

import {
  IconBtn,
  SidebarDivider,
  SidebarConatiner,
  SidebarHeader,
  SidenavBtnContainer,
  SbHNameContainer,
  SidebarBody,
  SbBElementContainer,
  SbBElement,
  SbHName,
} from "./StyledComponents";

const Sidebar = ({ setCllpse, cllpse, name, custIdData }) => {
  return (
    <>
      {custIdData?.data.custId === 1587 ? (
        <SidebarConatiner bckColor={"#1108df"}>
          <SidebarHeader>
            <SbHNameContainer>
              <SbHName>{name}</SbHName>
            </SbHNameContainer>

            <SidenavBtnContainer onClick={() => setCllpse(!cllpse)}>
              <IconBtn bckColor={"#1108df"} hoverColor={"red"} size={30}>
                &times;
              </IconBtn>
            </SidenavBtnContainer>
          </SidebarHeader>

          <SidebarDivider />

          <SidebarBody>
            <SbBElementContainer>
              <SbBElement onClick={() => setCllpse(!cllpse)} to="/dashboard">
                Dashboard
              </SbBElement>
            </SbBElementContainer>

            <SbBElementContainer>
              <SbBElement to="/map">Map</SbBElement>
            </SbBElementContainer>

            <SbBElementContainer>
              <SbBElement to="/alarmLogs">Alarm</SbBElement>
            </SbBElementContainer>

            {/* <SbBElementContainer>
          <SbBElement to="#">Add Customer</SbBElement>
        </SbBElementContainer>

        <SbBElementContainer>
          <SbBElement to="#">Manage Customer</SbBElement>
        </SbBElementContainer> */}
          </SidebarBody>
        </SidebarConatiner>
      ) : custIdData?.data.custId === 0 ? (
        <SidebarConatiner>
          <SidebarHeader>
            <SbHNameContainer>
              <SbHName>{name}</SbHName>
            </SbHNameContainer>

            <SidenavBtnContainer onClick={() => setCllpse(!cllpse)}>
              <IconBtn hoverColor={"red"} size={30}>
                &times;
              </IconBtn>
            </SidenavBtnContainer>
          </SidebarHeader>

          <SidebarDivider />

          <SidebarBody>
            <SbBElementContainer>
              <SbBElement
                onClick={() => setCllpse(!cllpse)}
                to="/adminDashboard"
              >
                Dashboard
              </SbBElement>
            </SbBElementContainer>

            <SbBElementContainer>
              <SbBElement to="/manageUsers">Manage Users</SbBElement>
            </SbBElementContainer>

            <SbBElementContainer>
              <SbBElement to="/addAccount">Add Account</SbBElement>
            </SbBElementContainer>

            {/* <SbBElementContainer>
          <SbBElement to="#">Add Customer</SbBElement>
        </SbBElementContainer>

        <SbBElementContainer>
          <SbBElement to="#">Manage Customer</SbBElement>
        </SbBElementContainer> */}
          </SidebarBody>
        </SidebarConatiner>
      ) : (
        <SidebarConatiner>
          <SidebarHeader>
            <SbHNameContainer>
              <SbHName>{name}</SbHName>
            </SbHNameContainer>

            <SidenavBtnContainer onClick={() => setCllpse(!cllpse)}>
              <IconBtn hoverColor={"red"} size={30}>
                &times;
              </IconBtn>
            </SidenavBtnContainer>
          </SidebarHeader>

          <SidebarDivider />

          <SidebarBody>
            <SbBElementContainer>
              <SbBElement onClick={() => setCllpse(!cllpse)} to="/dashboard">
                Dashboard
              </SbBElement>
            </SbBElementContainer>

            <SbBElementContainer>
              <SbBElement to="/map">Map</SbBElement>
            </SbBElementContainer>

            <SbBElementContainer>
              <SbBElement to="/alarmLogs">Alarm</SbBElement>
            </SbBElementContainer>

            {/* <SbBElementContainer>
          <SbBElement to="#">Add Customer</SbBElement>
        </SbBElementContainer>

        <SbBElementContainer>
          <SbBElement to="#">Manage Customer</SbBElement>
        </SbBElementContainer> */}
          </SidebarBody>
        </SidebarConatiner>
      )}
    </>
  );
};

export default Sidebar;
