import React from "react";
import Navbar from "../components/Navbar";
import {
  BgContainer,
  NavBottom,
  NavbarContainerBottom,
} from "../components/StyledComponents";
import {
  useClientDevicesLocationDataByIdQuery,
  useProfileQuery,
} from "../services/users.service";

import MapComponent from "../components/MapComponent";
import { ws_2 } from "../assests/Logos";

// const MapComponent = React.lazy(() => import("../components/MapComponent"));

const Map = () => {
  const profileQuery = useProfileQuery();
  const id = profileQuery.data?.data.custId;
  const email = profileQuery.data?.data.email;

  const clientDevicesLocationDataQuery =
    useClientDevicesLocationDataByIdQuery(id);

  // const devicesDetails = clientDevicesLocationDataQuery.data?.data;
  // console.log(devicesDetails);

  // const details = devicesDataQuery.data?.data;
  // console.log(devicesDetails);
  if (profileQuery.isLoading || clientDevicesLocationDataQuery.isLoading) {
    return (
      <div className="absolute text-xl text-black top-1/2 left-1/2">
        <h1>Loading......</h1>
      </div>
    );
  }
  return (
    <BgContainer className="bg-white">
      <Navbar />
      <div className="px-2 pt-3 pb-5 mt-3 bg-gray-400 border-2 border-solid">
        <MapComponent devicesDetails={clientDevicesLocationDataQuery} />
      </div>
      {id === 1587 ? (
        <NavBottom>
          <NavbarContainerBottom>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "176px",
                  height: "96px",
                  padding: "15px 10px 5px 10px",
                }}
                src={ws_2}
                alt="ws2"
              />
            </div>
            {email === "weststar@comdelta.com.my" ? (
              <p>Powered by Comdelta Technologies Sdn Bhd</p>
            ) : (
              <></>
            )}
          </NavbarContainerBottom>
        </NavBottom>
      ) : (
        <></>
      )}
    </BgContainer>
  );
};

export default Map;
