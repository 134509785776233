import React, { useState } from "react";

// React Table
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from "react-table";
import { TableContainer, TableStyles } from "./StyledComponents";

const TableComponent = ({ data, columns }) => {
  const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{" "}
        <input
          type={"text"}
          autoFocus={true}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Search....`}
          style={{
            fontSize: "1.1rem",
            border: "0",
            outline: "none",
          }}
        />
      </span>
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // Search / Filtering Props
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect
  );
  return (
    <TableContainer>
      <div className="p-0.5 flex justify-between items-center pb-2">
        <div className="w-full h-auto">
          <select
            className="p-1 m-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50]?.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full h-auto">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <TableStyles>
        <table className="w-full bg-gray-300" {...getTableProps()}>
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <td className="w-20 px-6 py-3 text-lg font-medium tracking-wider text-center text-black uppercase">
                  No.
                </td>
                {headerGroup.headers?.map((column) => (
                  <th
                    colSpan={visibleColumns.length}
                    scope="col"
                    className="px-6 py-3 text-lg font-medium tracking-wider text-black uppercase"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page?.map((row, i) => {
              prepareRow(row);
              const rowNumber = pageIndex * pageSize + i + 1;
              return (
                <tr {...row.getRowProps()}>
                  <td className="px-6 py-3 text-lg font-medium tracking-wider text-center text-black">
                    {rowNumber}
                  </td>
                  {row.cells?.map((cell) => {
                    // console.log(cell.row.values.deviceId);
                    return (
                      <td
                        className="px-6 py-3 text-lg font-medium tracking-wider text-center text-black "
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* 
              Pagination can be built however you'd like. 
              This is just a very basic UI implementation:
            */}
        <hr className="border-black border-solid" />
        <div className="flex flex-row items-center justify-between pt-5 pagination">
          <div className="w-full">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            {/* <span>
                  | Go to page:{" "}
                  <input
                    type={"number"}
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{
                      width: "100px",
                      border: "solid",
                      borderWidth: "2px",
                      outline: "none",
                    }}
                  />
                </span> */}
          </div>{" "}
          <div className="flex flex-row justify-end w-full">
            <button
              className="flex items-center justify-center w-auto px-1 py-1 text-sm font-medium text-black bg-gray-300 border border-transparent rounded-md hover:bg-gray-200 md:py-2 md:text-base md:px-5"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>{" "}
            <button
              className="flex items-center justify-center w-auto p-5 px-1 py-1 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md hover:bg-gray-100 md:py-2 md:text-base md:px-5"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"<"}
            </button>{" "}
            <button
              className="flex items-center justify-center w-auto p-5 px-1 py-1 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md hover:bg-gray-100 md:py-2 md:text-base md:px-5"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {">"}
            </button>{" "}
            <button
              className="flex items-center justify-center w-auto p-5 px-1 py-1 text-sm font-medium text-black bg-gray-300 border border-transparent rounded-md hover:bg-gray-200 md:py-2 md:text-base md:px-5"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>{" "}
        </div>
      </TableStyles>
    </TableContainer>
  );
};

export default TableComponent;
