import React from "react";

// styled components
import { LogoBox, SmallLogosContainer } from "./StyledComponents";

// logos
import {
  firstLogo,
  secondLogo,
  thirdLogo,
  fourthLogo,
  fifthLogo,
  sixthLogo,
  seventhLogo,
} from "../assests/Logos";

const FooterComponent = () => {
  return (
    <footer>
      <SmallLogosContainer>
        <LogoBox>
          <img src={firstLogo} alt="logo1" />
        </LogoBox>
        <LogoBox>
          <img src={seventhLogo} alt="logo1" />
        </LogoBox>
        <LogoBox>
          <img src={secondLogo} alt="logo1" />
        </LogoBox>
        <LogoBox>
          <img src={thirdLogo} alt="logo1" />
        </LogoBox>
        <LogoBox>
          <img src={fourthLogo} alt="logo1" />
        </LogoBox>
        <LogoBox>
          <img src={fifthLogo} alt="logo1" />
        </LogoBox>
        <LogoBox>
          <img src={sixthLogo} alt="logo1" />
        </LogoBox>
      </SmallLogosContainer>
    </footer>
  );
};

export default FooterComponent;
