// React Component
import React from "react";
import { useNavigate } from "react-router-dom";
//import { Link } from "react-router-dom";

// Navbar component
import Navbar from "../components/Navbar";

// Styled Components
import {
  BgContainer,
  DashboardBodyContainer,
  DashboardHeaderContainer,
  DashboardPageCountainer,
  DHNameContainer,
} from "../components/StyledComponents";

// Table component
// import DashboardDevicesTable from "../components/DashboardDevicesTable";

// React Query functions
import {
  useCustomersDataQuery,
  useProfileQuery,
} from "../services/users.service";

import TableComponent from "../components/TableComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEdit,
  faLaptopFile,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { openInNewTab } from "../js/helpers";

const AdminDashboard = () => {
  const navigate = useNavigate();

  const profileQuery = useProfileQuery();
  const customersDataQuery = useCustomersDataQuery();

  //   console.log(customersDataQuery.data?.data);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Devices",
        accessor: "",
        Cell: (props) => {
          const rowCustomerId = props.row.original.custId;
          const rowCustomerName = props.row.original.name;
          return (
            <div>
              <button
                className="w-4 h-1 pr-2 text-xl font-bold text-black bg-transparent border-none cursor-pointer"
                onClick={() =>
                  openInNewTab(
                    `/adminDevices/${rowCustomerId}?name=${rowCustomerName}`
                  )
                }
              >
                <FontAwesomeIcon icon={faLaptopFile} />
              </button>
            </div>
          );
        },
      },
      {
        Header: "Map",
        accessor: "",
        Cell: (props) => {
          const rowCustomerId = props.row.original.custId;
          const rowCustomerName = props.row.original.name;
          return (
            <div>
              <button
                className="w-4 h-1 pr-2 text-xl font-bold text-blue-600 bg-transparent border-none cursor-pointer"
                onClick={() =>
                  openInNewTab(
                    `/adminMap/${rowCustomerId}?name=${rowCustomerName}`
                  )
                }
              >
                <FontAwesomeIcon icon={faLocationDot} />
              </button>
            </div>
          );
        },
      },
      {
        Header: "Alarm",
        accessor: "",
        Cell: (props) => {
          const rowCustomerId = props.row.original.custId;
          const rowCustomerName = props.row.original.name;
          return (
            <div>
              <button
                className="w-4 h-1 pr-2 text-xl font-bold text-red-500 bg-transparent border-none cursor-pointer"
                onClick={() =>
                  openInNewTab(
                    `/adminAlarm/${rowCustomerId}?name=${rowCustomerName}`
                  )
                }
              >
                <FontAwesomeIcon icon={faBell} />
              </button>
            </div>
          );
        },
      },
      {
        Header: "Customer ID",
        accessor: "custId",
      },
      {
        Header: "Join Date",
        accessor: "joinDate",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: (props) => {
          //   console.log(props);
          const rowEmail = props.row.original.email;
          return (
            <div>
              <button
                className="p-2"
                onClick={() => navigate(`/editAccount/${rowEmail}`)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const data = React.useMemo(
    () =>
      (customersDataQuery.data?.data ?? []).map((customer) => {
        return {
          ...customer,
          joinDate: new Date(customer.joinDate).toLocaleString(),
        };
      }),
    [customersDataQuery.data?.data]
  );

  if (profileQuery.isLoading || customersDataQuery.isLoading) {
    return (
      <div className="absolute text-2xl font-bold text-black left-1/2 top-1/2">
        Loading...
      </div>
    );
  }

  return (
    <BgContainer>
      <Navbar />
      <DashboardPageCountainer>
        <DashboardHeaderContainer>
          <DHNameContainer>
            <h1>{profileQuery.data?.data.name}</h1>
          </DHNameContainer>
        </DashboardHeaderContainer>

        <DashboardBodyContainer>
          <TableComponent columns={columns} data={data} />
        </DashboardBodyContainer>
      </DashboardPageCountainer>

      {/* <NavBottom>
        <NavbarContainerBottom>
          <p>Powered by Comdelta Technologies Sdn Bhd</p>
        </NavbarContainerBottom>
      </NavBottom> */}
    </BgContainer>
  );
};

export default AdminDashboard;
