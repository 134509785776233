import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
// import { useForm } from "@tanstack/react-form";

// styled components
import {
  BgContainer,
  FormBtn,
  FormBtnContainer,
  FormContentContainer,
  FormPageCountainer,
} from "../components/StyledComponents";

// form package
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// Form label and input box
import { FormElements, SelectOptionsField } from "../components/FormElements";

// NavBar component
import Navbar from "../components/Navbar";

// Add Client mutation function
import { useEditAccountMutation } from "../services/auth.service";
import {
  useAccountDataByIdQuery,
  useModeratorsDataQuery,
} from "../services/users.service";

// Toast notification
import toast, { Toaster } from "react-hot-toast";

// Status Code
import { StatusCodes } from "http-status-codes";

const EditAccount = () => {
  const { id } = useParams();

  const accountDataQuery = useAccountDataByIdQuery(id);
  const moderatorsDataQuery = useModeratorsDataQuery();

  const data = useMemo(() => {
    if (accountDataQuery.data?.data) {
      return {
        ...accountDataQuery.data.data,
      };
    }
  }, [accountDataQuery.data?.data]);

  const moderatorsData = useMemo(
    () =>
      (moderatorsDataQuery.data?.data ?? []).map((moderator) => {
        return {
          label: moderator.name,
          value: moderator.moderatorId,
        };
      }),
    [moderatorsDataQuery.data?.data]
  );

  const fullData = [
    { label: "Select Moderator", value: "" },
    ...moderatorsData,
  ];

  const editAccountMutation = useEditAccountMutation({
    onSuccess: () => {
      formik.resetForm({ values: "" });

      // complete submission
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err.response) {
        if (err.response.status === StatusCodes.BAD_REQUEST) {
          const { message } = err.response.data;

          toast.error(message);
        }
      } else {
        toast.error("Something went wrong");
        console.error(err);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      custId: "",
      role: "",
      moderatedBy: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
      oldPassword: Yup.string()
        .min(8, "Must be 8 characters or more")
        .max(20, "Password is too long, must be less than 20 characters"),
      newPassword: Yup.string()
        .min(8, "Must be 8 characters or more")
        .max(20, "Password is too long, must be less than 20 characters"),
      confirmNewPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "Passwords must match"
      ),
      //   custId: Yup.string().required("Customer Id is Required"),
      //   role: Yup.string().required("Role is Required"),
    }),
    onSubmit: (values) => {
      editAccountMutation.mutate({ values, id });
    },
  });

  useEffect(() => {
    if (data) {
      formik.setValues({
        ...data,
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (accountDataQuery.isLoading) {
    return null;
  }

  return (
    <BgContainer>
      <Navbar />

      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>

      <FormPageCountainer>
        <FormContentContainer>
          <div>
            <h2>Edit Account</h2>
          </div>
          <FormikProvider value={formik}>
            <Form>
              <FormElements
                id="name"
                label="Name"
                name="name"
                type="text"
                placeholder="Enter your name"
              />

              <FormElements
                id="email"
                label="Email"
                name="email"
                type="email"
                placeholder="Enter your email"
              />

              <FormElements
                id="custId"
                label="Customer ID"
                name="custId"
                type="text"
                placeholder="Enter your customer ID"
              />

              <FormElements
                id="role"
                label="Role"
                name="role"
                type="text"
                readOnly
                disabled
                style={{ textTransform: "uppercase" }}
                placeholder="Enter your role"
              />

              {formik.values.role === "customer" && (
                <SelectOptionsField
                  id="moderatedBy"
                  label="Moderated By"
                  name="moderatedBy"
                  options={fullData}
                />
              )}

              <FormElements
                id="oldPassword"
                label="Old Password"
                name="oldPassword"
                type="password"
                placeholder="Enter your password"
              />

              <FormElements
                id="newPassword"
                label="New Password"
                name="newPassword"
                type="password"
                placeholder="Enter your password"
              />

              <FormElements
                id="confirmNewPassword"
                label="Confirm New Password"
                name="confirmNewPassword"
                type="password"
                placeholder="Confirm your password"
              />

              {!formik.isSubmitting && (
                <FormBtnContainer>
                  <FormBtn type="submit">Edit Account</FormBtn>
                </FormBtnContainer>
              )}

              {formik.isSubmitting && (
                <FormBtnContainer>
                  <FormBtn type="submit">Edit Account</FormBtn>
                </FormBtnContainer>
              )}
            </Form>
          </FormikProvider>
        </FormContentContainer>
      </FormPageCountainer>
    </BgContainer>
  );
};

export default EditAccount;
