import React, { useMemo, useState } from "react";

// JSPdf library
import jsPDF from "jspdf";
import "jspdf-autotable";
import { logoPdf } from "../assests/logoPdf";
import { logoWs } from "../assests/logoWs";

// Styled Components
import {
  ActionsContainer,
  CustButton,
  TableContainer,
  TableStyles,
} from "../components/StyledComponents";

// Chart components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Date Picker Components
import DatePicker from "react-datepicker";

// // FontAwesome Icons
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   // faBatteryEmpty,
//   // faBatteryFull,
//   // faBatteryHalf,
//   // faBatteryQuarter,
//   // faBatteryThreeQuarters,
//   // faList,
//   // faPenSquare,
//   // faSlash,
//   // faTrash,
//   faLightbulb,
// } from "@fortawesome/free-solid-svg-icons";

// // React Icons
// import {
//   MdSignalCellular1Bar,
//   MdSignalCellular2Bar,
//   MdSignalCellular3Bar,
//   MdSignalCellular4Bar,
// } from "react-icons/md";
// import {
//   FcChargeBattery,
//   FcEmptyBattery,
//   FcFullBattery,
//   FcHighBattery,
//   FcLowBattery,
//   FcMiddleBattery,
// } from "react-icons/fc";

// Query functions
import { useClientBatteryLogsHistoryByIdQuery } from "../services/users.service";

// Toast Notification
// import toast, { Toaster } from "react-hot-toast";

// Excel Export Component
import { CSVLink } from "react-csv";

// Toast Notification
// import toast, { Toaster } from "react-hot-toast";

// Table component
import TableComponent from "./TableComponent";

const BatteryLogsComponent = ({
  custId,
  id,
  deviceName,
  custEmail,
  custName,
}) => {
  // const currentDate = new Date().toISOString().slice(0, 10);
  const [showChart, setShowChart] = useState(true);
  const [showTable, setShowTable] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const sDate = startDate.toISOString().slice(0, 10);
  const eDate = endDate.toISOString().slice(0, 10);

  const clientBatteryHistoryById = useClientBatteryLogsHistoryByIdQuery(
    id,
    sDate,
    eDate
  );
  // console.log(id);
  // console.log(clientDeviceHistoryById.data?.data);
  // console.log(sDate);
  // console.log(eDate);

  let updateTime;
  try {
    updateTime = clientBatteryHistoryById.data?.data.updatetime.map((t) => t);
  } catch (error) {
    // toast.error("No data found");
    console.log(error);
  }

  const current =
    clientBatteryHistoryById.data?.data.history?.lampcurrent.val.map((c) => c);

  const voltage =
    clientBatteryHistoryById.data?.data.history?.lampvoltage.val.map((v) => v);

  const temperature =
    clientBatteryHistoryById.data?.data.history?.temperature.val.map(
      (temp) => temp
    );

  const allData = updateTime?.map((log, i) => {
    return {
      index: i + 1,
      lamp_no: deviceName,
      update_time: log,
      current: current[i],
      voltage: voltage[i],
      temperature: temperature[i],
    };
  });

  const data = React.useMemo(
    () =>
      (allData ?? []).map((log) => {
        return {
          index: log.index,
          lamp_no: log.lamp_no,
          update_time: log.update_time,
          current: log.current,
          voltage: log.voltage,
          temperature: log.temperature,
        };
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allData]
  );

  const columns = useMemo(
    () => [
      {
        Header: "No.",
        id: "index",
        accessor: (_row, i) => i + 1,
      },
      {
        Header: "Device ID",

        accessor: "lamp_no",
      },
      {
        Header: "Voltage (V)",

        accessor: "voltage",
      },
      {
        Header: "Current (A)",

        accessor: "current",
      },
      {
        Header: "Temperature (C)",
        accessor: "temperature",
      },
      {
        Header: "Time Update",
        accessor: "update_time",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(25);

    const title = "Comdelta Technologies Sdn Bhd.";
    const headers = [
      { header: "No.", dataKey: "index" },
      { header: "Device ID", dataKey: "lamp_no" },
      { header: "Current (A)", dataKey: "current" },
      { header: "Voltage (V)", dataKey: "voltage" },
      { header: "Temperature (C)", dataKey: "temperature" },
      { header: "Time Update", dataKey: "update_time" },
    ];
    // { header: "Signal Status", dataKey: "signal_status" },
    // { header: "Light", dataKey: "light_brightness" },
    let content = {
      startY: 135,
      columns: headers,
      body: data,
      styles: {
        halign: "center",
        cellPadding: { top: 10, right: 5, bottom: 10, left: 5 },
        fontSize: 12,
      },
    };

    const addFooterCopyRight = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();

      doc.setFont("helvetica", "italic", "bold");
      doc.setFontSize(8);
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        custEmail === "weststar@comdelta.com.my"
          ? doc.text(
              `Copyright © ${new Date().getFullYear()}. All rights reserved.`,
              doc.internal.pageSize.width / 2,
              570,
              {
                align: "center",
              }
            )
          : custEmail === "mbip1-johor23@weststar.com.my"
          ? doc.text(
              `Copyright © ${new Date().getFullYear()}. All rights reserved.`,
              doc.internal.pageSize.width / 2,
              570,
              {
                align: "center",
              }
            )
          : doc.text(
              `Copyright © ${new Date().getFullYear()} Comdelta Technologies Sdn Bhd. All rights reserved.`,
              doc.internal.pageSize.width / 2,
              570,
              {
                align: "center",
              }
            );
        // doc.text(
        //   `Copyright © ${new Date().getFullYear()} Comdelta Technologies Sdn Bhd. All rights reserved.`,
        //   doc.internal.pageSize.width / 2,
        //   570,
        //   {
        //     align: "center",
        //   }
        // );
      }
    };

    const addFooterPageNumber = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();

      doc.setFont("helvetica", "italic");
      doc.setFontSize(8);
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text("Page " + String(i) + " of " + String(pageCount), 40, 570, {
          align: "center",
        });
      }
    };

    custId === 1587
      ? doc.addImage(logoWs, "PNG", 360, 15, 140, 80)
      : doc.addImage(logoPdf, "PNG", 360, 15, 140, 80);

    custEmail === "weststar@comdelta.com.my"
      ? doc.text(custName, marginLeft, 125)
      : custEmail === "mbip1-johor23@weststar.com.my"
      ? doc.text(custName, marginLeft, 125)
      : doc.text(title, marginLeft, 125);

    doc.autoTable(content);
    addFooterCopyRight(doc);
    addFooterPageNumber(doc);
    doc.save(`Device Battery Logs ${deviceName}.pdf`);
  };

  const headers = [
    { label: "No.", key: "index" },
    { label: "Device ID", key: "lamp_no" },
    { label: "Current (A)", key: "current" },
    { label: "Voltage (V)", key: "voltage" },
    { label: "Temperature (C)", key: "temperature" },
    { label: "Time Update", key: "update_time" },
  ];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Chart for Device: ${data[0]?.lamp_no}`,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        min: 0,
        title: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
        },
      },
    },
  };

  const labels = data?.map((d) => d.update_time);

  const currentLogs = data?.map((c) => c.current);
  const voltageLogs = data?.map((v) => v.voltage);
  const temperatureLogs = data?.map((temp) => temp.temperature);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Current (A)",
        data: currentLogs,
        borderColor: "rgb(204, 204, 0)",
        backgroundColor: "rgba(204, 204, 0, 0.5)",
      },
      {
        label: "Voltage (V)",
        data: voltageLogs,
        borderColor: "rgb(51, 51, 255)",
        backgroundColor: "rgba(51, 51, 255, 0.5)",
      },
      {
        label: "Temperature (C)",
        data: temperatureLogs,
        borderColor: "rgb(204, 82, 0)",
        backgroundColor: "rgba(204, 82, 0, 0.5)",
      },
    ],
  };

  if (clientBatteryHistoryById.isLoading) {
    return (
      <div className="absolute text-xl text-black top-1/2 left-1/2">
        <h1>Loading......</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1 p-1 mx-2 mt-5">
      {/* <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div> */}
      <TableContainer>
        <ActionsContainer align={"space-between"}>
          <div className="flex items-center justify-between w-auto px-2 py-2 ipSc:flex-col">
            <div>
              <CustButton color={"#a11f1f"} onClick={() => exportPDF()}>
                Download Pdf
              </CustButton>
            </div>
            <div>
              <CustButton color={"#33cc00"}>
                <CSVLink
                  className="text-white hover:text-black"
                  data={data ?? []}
                  headers={headers}
                  filename={`Device Battery Logs ${deviceName}.csv`}
                >
                  Download Excel
                </CSVLink>
              </CustButton>
            </div>
          </div>

          <div className="flex items-center justify-between w-1/2 ipSc:flex-col ">
            <div>
              <CustButton
                color={"#005ce6"}
                onClick={() => clientBatteryHistoryById.refetch()}
              >
                Confirm Date
              </CustButton>
            </div>
            <div className="flex flex-row items-center justify-center">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate}
              />
              <h4 className="px-1 mx-1 text-center text-white">To</h4>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </div>
        </ActionsContainer>

        <TableStyles>
          <div>
            <h2>Battery Logs</h2>
          </div>
          <div className="flex py-2 my-2 bg-white justify-evenly ">
            <button
              onClick={() => {
                setShowChart(!showChart);
                setShowTable(false);
              }}
            >
              Chart
            </button>
            <button
              onClick={() => {
                setShowTable(!showTable);
                setShowChart(false);
              }}
            >
              Table
            </button>
          </div>
          {showChart && (
            <div className="bg-white">
              {updateTime ? (
                <Line options={options} data={chartData} />
              ) : (
                <h1 className="text-center">No Data</h1>
              )}
            </div>
          )}
          {showTable && <TableComponent data={data} columns={columns} />}
        </TableStyles>
      </TableContainer>
    </div>
  );
};

export default BatteryLogsComponent;
