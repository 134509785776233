import React, { useState } from "react";

// styled components
import { TextLabelContainer } from "./StyledComponents";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

// form package
import { useField } from "formik";

const FormElements = ({ ...props }) => {
  const [field, meta] = useField(props);
  const [show, setShow] = useState(false);

  return (
    <TextLabelContainer>
      <label className="label" htmlFor={props.name}>
        {props.label}:
      </label>
      {props.type !== "password" && (
        <input
          className="input"
          invalid={meta.touched && meta.error ? "true" : "false"}
          {...field}
          {...props}
        />
      )}

      {props.type === "password" && (
        <input
          className="input"
          invalid={meta.touched && meta.error ? "true" : "false"}
          {...field}
          {...props}
          type={show ? "text" : "password"}
        />
      )}

      {props.type === "password" && (
        <p className="eye" onClick={() => setShow(!show)}>
          {show && <AiFillEye />}
          {!show && <AiFillEyeInvisible />}
        </p>
      )}

      {meta.touched && meta.error ? (
        <div className="errMsg">{meta.error}</div>
      ) : (
        <div className="errMsg" style={{ visibility: "hidden" }}>
          .
        </div>
      )}
    </TextLabelContainer>
  );
};

const SelectOptionsField = ({ options, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <TextLabelContainer>
      <label className="label" htmlFor={props.name}>
        {props.label}:
      </label>

      <select
        className="select"
        {...props}
        {...field}
        invalid={meta.touched && meta.error ? "true" : "false"}
      >
        {options?.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {meta.touched && meta.error ? (
        <div className="errMsg">{meta.error}</div>
      ) : (
        <div className="errMsg" style={{ visibility: "hidden" }}>
          .
        </div>
      )}
    </TextLabelContainer>
  );
};

export { FormElements, SelectOptionsField };
