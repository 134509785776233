import React from "react";
// react router dom
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Spinner loader css
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// React Circular Progressbar
import "react-circular-progressbar/dist/styles.css";

// Mapbox
import "mapbox-gl/dist/mapbox-gl.css";

// Date Picker CSS
import "react-datepicker/dist/react-datepicker.css";

//import "./App.css";
import SignUp from "./views/SignUp";
import Login from "./views/Login";
import AdminDashboard from "./views/AdminDashboard";
import AdminDevices from "./views/AdminDevices";
import AdminMap from "./views/AdminMap";
import AdminAlarmLogs from "./views/AdminAlarmLogs";
import ModeratorDashboard from "./views/ModeratorDashboard";
import ModeratorDevices from "./views/ModeratorDevices";
import ModeratorMap from "./views/ModeratorMap";
import ModeratorAlarmLogs from "./views/ModeratorAlarmLogs";
import EditAccount from "./views/EditAccount";
import ManageUsers from "./views/ManageUsers";
import Dashboard from "./views/Dashboard";
import DeviceLogs from "./views/DeviceLogs";
import Home from "./views/Home";
import AlarmLogs from "./views/AlarmLogs";
import Map from "./views/Map";
import { RouteGuard, RouteGuardLogin } from "./components/RouteGuard";
// import UserProfile from "./views/UserProfile";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/login" element={<Login />} /> */}

        <Route
          path="/login"
          element={
            <RouteGuardLogin>
              <Login />
            </RouteGuardLogin>
          }
        />

        <Route
          path="/adminDashboard"
          element={
            <RouteGuard role={"admin"} redirect={"/login"}>
              <AdminDashboard />
            </RouteGuard>
          }
        />

        <Route
          path="/adminDevices/:id"
          element={
            <RouteGuard role={"admin"} redirect={"/login"}>
              <AdminDevices />
            </RouteGuard>
          }
        />

        <Route
          path="/adminMap/:id"
          element={
            <RouteGuard role={"admin"} redirect={"/login"}>
              <AdminMap />
            </RouteGuard>
          }
        />

        <Route
          path="/adminAlarm/:id"
          element={
            <RouteGuard role={"admin"} redirect={"/login"}>
              <AdminAlarmLogs />
            </RouteGuard>
          }
        />

        <Route
          path="/moderatorDashboard"
          element={
            <RouteGuard role={"moderator"} redirect={"/login"}>
              <ModeratorDashboard />
            </RouteGuard>
          }
        />

        <Route
          path="/moderatorDevices/:id"
          element={
            <RouteGuard role={"moderator"} redirect={"/login"}>
              <ModeratorDevices />
            </RouteGuard>
          }
        />

        <Route
          path="/moderatorMap/:id"
          element={
            <RouteGuard role={"moderator"} redirect={"/login"}>
              <ModeratorMap />
            </RouteGuard>
          }
        />

        <Route
          path="/moderatorAlarm/:id"
          element={
            <RouteGuard role={"moderator"} redirect={"/login"}>
              <ModeratorAlarmLogs />
            </RouteGuard>
          }
        />

        <Route
          path="/manageUsers"
          element={
            <RouteGuard role={"admin"} redirect={"/login"}>
              <ManageUsers />
            </RouteGuard>
          }
        />

        <Route
          path="/editAccount/:id"
          element={
            <RouteGuard role={"admin"} redirect={"/login"}>
              <EditAccount />
            </RouteGuard>
          }
        />

        <Route
          path="/dashboard"
          element={
            <RouteGuard role={"customer"} redirect={"/login"}>
              <Dashboard />
            </RouteGuard>
          }
        />

        <Route
          path="/deviceLogs/:deviceName"
          element={
            <RouteGuard
              role={["customer", "admin", "moderator"]}
              redirect={"/login"}
            >
              <DeviceLogs />
            </RouteGuard>
          }
        />

        <Route
          path="/alarmLogs"
          element={
            <RouteGuard role={"customer"} redirect={"/login"}>
              <AlarmLogs />
            </RouteGuard>
          }
        />

        <Route
          path="/addAccount"
          element={
            <RouteGuard role={"admin"} redirect={"/login"}>
              <SignUp />
            </RouteGuard>
          }
        />

        <Route
          path="/map"
          element={
            <RouteGuard role={"customer"} redirect={"/login"}>
              <Map />
            </RouteGuard>
          }
        />

        {/* <Route
          path="/userProfile"
          element={
            <RouteGuard role={"customer"} redirect={"/login"}>
              <UserProfile />
            </RouteGuard>
          }
        /> */}
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        {/* <Route path="/deviceLogs/:deviceName/:id" element={<DeviceLogs />} /> */}
        {/* <Route path="/alarmLogs" element={<AlarmLogs />} /> */}
        {/* <Route path="/signUp" element={<SignUp />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
