// React Component
import React from "react";
//import { Link } from "react-router-dom";

// Navbar component
import Navbar from "../components/Navbar";

// Styled Components
import {
  BgContainer,
  DashboardBodyContainer,
  DashboardHeaderContainer,
  DashboardPageCountainer,
  DHBarContainer,
  DHBSubContainer,
  DHNameContainer,
  NavbarContainerBottom,
  NavBottom,
} from "../components/StyledComponents";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleGroup,
  faPowerOff,
  faTablet,
} from "@fortawesome/free-solid-svg-icons";

// Circule bar component
import CounterCircleBar from "../components/CounterCircleBar";

// Table component
import DashboardDevicesTable from "../components/DashboardDevicesTable";

// React Query functions
import {
  useClientDevicesDataQuery,
  useProfileQuery,
} from "../services/users.service";
import { ws_2 } from "../assests/Logos";

// const MapDashboard = React.lazy(() => import("../components/MapDashboard"));

const Dashboard = () => {
  const profileQuery = useProfileQuery();
  const id = profileQuery.data?.data.custId;
  const email = profileQuery.data?.data.email;
  const name = profileQuery.data?.data.name;
  const clientDevicesDataQuery = useClientDevicesDataQuery(id);
  // console.log(clientDevicesDataQuery.data?.data);
  // console.log(profileQuery.data?.data.items);
  // const devicesData = useDevicesDataQuery();
  //   const devicesLogs = useDeviceLogsQuery();
  //   const clientsData = useClientsDataQuery();

  // console.log(clientDevicesDataQuery.data?.data);
  const totalDevices = clientDevicesDataQuery.data?.data?.items
    ? clientDevicesDataQuery.data?.data.items.length
    : 0;

  const activeDevices = clientDevicesDataQuery.data?.data?.items
    ? clientDevicesDataQuery.data?.data.items.filter((device) => {
        return device.fields.signal_status !== "0";
      }).length
    : 0;
  const inactiveDevices =
    totalDevices - activeDevices ? totalDevices - activeDevices : 0;

  // const endActive = (activeDevices / totalDevices) * 100;

  // const endInactive = (inactiveDevices / totalDevices) * 100;
  //   const clients = clientsData.data?.data.length;

  //   const devicesLogsReverse = devicesLogs.data?.data.reverse();

  //   const devicesElogs = devicesData.data?.data.map((device) => {
  //     return devicesLogsReverse?.find((status) => {
  //       if (device.deviceId === status.device_id) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     });
  //   });

  //   const devicesStatus = devicesElogs?.filter((status) => {
  //     if (status?.rssi !== 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });

  //   const activeDevices = devicesStatus?.length;
  //   const inactiveDevices = devices - activeDevices;

  //   const isAdmin = profileQuery.data?.data.userType === "admin";
  //   // if (profileQuery.error) return "An error has occurred: " + error.message;
  //   // console.log(profileQuery.isError);
  //   // if (!profileQuery.data) {
  //   //   return <Navigate to="/login" />;
  //   // }
  //   const [isShowMap, setIsShowMap] = useState(false);
  //   useEffect(() => {
  //     if (!isShowMap) {
  //       const timer = setTimeout(() => {
  //         setIsShowMap(true);
  //       }, 1500);
  //       return () => clearTimeout(timer);
  //     }
  //   }, [isShowMap]);

  return (
    <BgContainer>
      <Navbar />
      <DashboardPageCountainer>
        <DashboardHeaderContainer>
          <DHNameContainer>
            <h1>{profileQuery.data?.data.name}</h1>
          </DHNameContainer>
          <DHBarContainer>
            <DHBSubContainer>
              <div className="hidden">
                <CounterCircleBar
                  end="100"
                  rgb="rgba(51, 102, 255, 1)"
                  label="Clients"
                  num={10}
                  iconColor={"rgb(128, 159, 255)"}
                  icon={<FontAwesomeIcon icon={faPeopleGroup} />}
                />
              </div>

              <div>
                <CounterCircleBar
                  end="100"
                  rgb="rgba(51, 204, 255, 1)"
                  label="Devices"
                  num={totalDevices}
                  iconColor={"rgb(128, 223, 255)"}
                  icon={<FontAwesomeIcon icon={faTablet} />}
                />
              </div>
            </DHBSubContainer>

            <DHBSubContainer>
              <div>
                <CounterCircleBar
                  end="100"
                  rgb="rgba(51, 204, 51, 1)"
                  label="Active"
                  num={activeDevices}
                  iconColor={"rgb(0, 255, 0)"}
                  icon={<FontAwesomeIcon icon={faPowerOff} />}
                />
              </div>
              <div>
                <CounterCircleBar
                  end="0"
                  rgb="rgba(255, 0, 0, 1)"
                  label="Inactive"
                  num={inactiveDevices}
                  iconColor={"rgb(255, 77, 77)"}
                  icon={<FontAwesomeIcon icon={faPowerOff} />}
                />
              </div>
            </DHBSubContainer>
          </DHBarContainer>
        </DashboardHeaderContainer>

        <DashboardBodyContainer>
          <DashboardDevicesTable
            devicesData={clientDevicesDataQuery}
            custId={id}
            custEmail={email}
            custName={name}
          />
        </DashboardBodyContainer>
      </DashboardPageCountainer>
      {id === 1587 ? (
        <NavBottom>
          <NavbarContainerBottom>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "176px",
                  height: "96px",
                  padding: "15px 10px 5px 10px",
                }}
                src={ws_2}
                alt="ws2"
              />
            </div>
            {email === "weststar@comdelta.com.my" ? (
              <p>Powered by Comdelta Technologies Sdn Bhd</p>
            ) : (
              <></>
            )}
          </NavbarContainerBottom>
        </NavBottom>
      ) : (
        <></>
      )}
    </BgContainer>
  );
};

export default Dashboard;
