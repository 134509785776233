// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// React Table Components
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from "react-table";

// JSPdf library
import jsPDF from "jspdf";
import "jspdf-autotable";
import { logoPdf } from "../assests/logoPdf";
import { logoWs } from "../assests/logoWs";

// Styled Components
import {
  ActionsContainer,
  CustButton,
  LightContainer,
  TableContainer,
  TableStyles,
} from "./StyledComponents";

// FontAwesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faBatteryEmpty,
  // faBatteryFull,
  // faBatteryHalf,
  // faBatteryQuarter,
  // faBatteryThreeQuarters,
  // faList,
  // faPenSquare,
  // faSlash,
  // faTrash,
  faLightbulb,
  faList,
} from "@fortawesome/free-solid-svg-icons";

// React Icons
import {
  MdSignalCellular1Bar,
  MdSignalCellular2Bar,
  MdSignalCellular3Bar,
  MdSignalCellular4Bar,
} from "react-icons/md";
import {
  FcChargeBattery,
  FcEmptyBattery,
  FcFullBattery,
  FcHighBattery,
  FcLowBattery,
  FcMiddleBattery,
} from "react-icons/fc";

// Popup box
import toast, { Toaster } from "react-hot-toast";

// // React Query functions
import { useControlLightMutation } from "../services/auth.service";
// import { useProfileQuery } from "../services/users.service";

// Status code
import { StatusCodes } from "http-status-codes";

// Formik Packages
import { FormikProvider, useFormik, Form, Field } from "formik";

const DashboardDevicesTable = ({
  devicesData,
  custId,
  custEmail,
  custName,
}) => {
  const navigate = useNavigate();
  // console.log(custEmail);

  // console.log(devicesData.data?.data.items);

  const controlLightMutation = useControlLightMutation({
    onError: (err) => {
      if (err.response) {
        if (err.response.status === StatusCodes.BAD_REQUEST) {
          // const { message } = err.response.data;
          console.log(err.response);
          // if (message.includes("exist")) {
          //   toast.error(message);
          // } else {
          //   toast.error(message);
          // }
        }
      }
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "No.",
        id: "id",
        accessor: (_row, i) => i + 1,
      },
      {
        Header: "Street Lamp ID",
        accessor: "lamp_no",
      },
      {
        Header: "PN",
        accessor: "module_id",
      },
      {
        Header: "Signal Strength",
        accessor: "signal_status",
        Cell: (props) => {
          const rowRssi = props.row.values.signal_status;
          return (
            <div className="flex items-center justify-center">
              {rowRssi === 0 && (
                <>
                  <span style={{ color: "black" }}>
                    <MdSignalCellular1Bar />
                  </span>
                  {rowRssi}
                </>
              )}
              {rowRssi >= 10 && rowRssi <= 30 && (
                <>
                  <span style={{ color: "red" }}>
                    <MdSignalCellular2Bar />
                  </span>
                  {rowRssi}
                </>
              )}
              {rowRssi >= 31 && rowRssi <= 60 && (
                <>
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    <MdSignalCellular3Bar />
                  </span>
                  {rowRssi}
                </>
              )}
              {rowRssi >= 61 && rowRssi <= 100 && (
                <>
                  <span style={{ color: "green" }}>
                    <MdSignalCellular4Bar />
                  </span>
                  {rowRssi}
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: "Lamp Power (W)",
        accessor: "light_power",
      },
      custId === 1685
        ? {
            Header: "Input Voltage (V)",
            accessor: "bat_voltage",
            Cell: (props) => {
              const rowBattery = props.row.values.bat_voltage;

              return (
                <div className="flex items-center justify-center">
                  <span>{rowBattery}</span>
                </div>
              );
            },
          }
        : {
            Header: "Battery Percentage (%)",
            accessor: "bat_voltage",
            Cell: (props) => {
              const rowBattery = props.row.values.bat_voltage;
              const rowBatteryStatus = props.row.original.bat_charging_stage;
              return (
                <div className="flex items-center justify-center">
                  {rowBatteryStatus !== "MPPT charging" ? (
                    rowBattery <= 10.4 ? (
                      <>
                        <span style={{ fontSize: "25px" }}>
                          <FcEmptyBattery />
                        </span>
                        0%
                      </>
                    ) : rowBattery >= 10.5 && rowBattery <= 11.0 ? (
                      <>
                        <span style={{ fontSize: "25px" }}>
                          <FcLowBattery />
                        </span>
                        20%
                      </>
                    ) : rowBattery >= 11.5 && rowBattery <= 11.9 ? (
                      <>
                        <span style={{ fontSize: "25px" }}>
                          <FcMiddleBattery />
                        </span>
                        50%
                      </>
                    ) : rowBattery >= 12.0 && rowBattery <= 12.5 ? (
                      <>
                        <span style={{ fontSize: "25px" }}>
                          <FcHighBattery />
                        </span>
                        80%
                      </>
                    ) : rowBattery <= 15 ? (
                      <>
                        <span style={{ fontSize: "25px" }}>
                          <FcFullBattery />
                        </span>
                        100%
                      </>
                    ) : rowBattery <= 22.3 ? (
                      <>
                        <span style={{ fontSize: "25px" }}>
                          <FcEmptyBattery />
                        </span>
                        0%
                      </>
                    ) : rowBattery >= 22.4 && rowBattery <= 23.3 ? (
                      <>
                        <span style={{ fontSize: "25px" }}>
                          <FcLowBattery />
                        </span>
                        20%
                      </>
                    ) : rowBattery >= 23.4 && rowBattery <= 24.3 ? (
                      <>
                        <span style={{ fontSize: "25px" }}>
                          <FcMiddleBattery />
                        </span>
                        50%
                      </>
                    ) : rowBattery >= 24.4 && rowBattery <= 25.7 ? (
                      <>
                        <span style={{ fontSize: "25px" }}>
                          <FcHighBattery />
                        </span>
                        80%
                      </>
                    ) : rowBattery <= 29 ? (
                      <>
                        <span style={{ fontSize: "25px" }}>
                          <FcFullBattery />
                        </span>
                        100%
                      </>
                    ) : (
                      <span>100%</span>
                    )
                  ) : (
                    <>
                      <span style={{ color: "green", fontSize: "30px" }}>
                        <FcChargeBattery />
                      </span>
                      {rowBattery <= 9.99 ? (
                        <>0%</>
                      ) : rowBattery >= 10.0 && rowBattery <= 12.7 ? (
                        <>10%</>
                      ) : rowBattery >= 12.8 && rowBattery <= 12.9 ? (
                        <>30%</>
                      ) : rowBattery >= 13.0 && rowBattery <= 13.2 ? (
                        <>60%</>
                      ) : rowBattery >= 13.3 && rowBattery <= 13.4 ? (
                        <>80%</>
                      ) : rowBattery >= 13.5 && rowBattery <= 14.6 ? (
                        <>100%</>
                      ) : rowBattery >= 19.9 && rowBattery <= 23.9 ? (
                        <>0%</>
                      ) : rowBattery >= 24.1 && rowBattery <= 25.5 ? (
                        <>10%</>
                      ) : rowBattery >= 25.6 && rowBattery <= 26.0 ? (
                        <>30%</>
                      ) : rowBattery >= 26.1 && rowBattery <= 26.4 ? (
                        <>60%</>
                      ) : rowBattery >= 26.5 && rowBattery <= 27.0 ? (
                        <>80%</>
                      ) : rowBattery >= 27.1 ? (
                        <>100%</>
                      ) : (
                        <span>100%</span>
                      )}
                    </>
                  )}
                </div>
              );
            },
          },
      {
        Header: "Solar Voltage (V)",
        accessor: "solar_voltage",
      },
      {
        Header: "Solar Power (W)",
        accessor: "solar_power",
      },
      {
        Header: "Light",
        accessor: "light_brightness",
        Cell: (props) => {
          const rowLight = props.row.values.light_brightness;
          return (
            <div>
              {rowLight === "0" && (
                <span style={{ color: "red" }}>
                  <FontAwesomeIcon icon={faLightbulb} />
                </span>
              )}
              {rowLight > "0" && (
                <span style={{ color: "green" }}>
                  <FontAwesomeIcon icon={faLightbulb} />
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Last Update",
        accessor: "update_timestamp",
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: (props) => {
          const rowDeviceId = props.row.original.deviceId;
          const deviceName = props.row.original.lamp_no;
          const rowBattery = props.row.original.bat_voltage;
          // console.log(rowBattery);
          return (
            <div>
              <button
                className="p-2"
                onClick={() =>
                  navigate(`/deviceLogs/${deviceName}`, {
                    state: {
                      batVolt: rowBattery,
                      devId: rowDeviceId,
                      devName: deviceName,
                    },
                  })
                }
              >
                <FontAwesomeIcon icon={faList} />
              </button>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  // console.log(devicesData.data?.data);

  const data = React.useMemo(
    () =>
      (devicesData.data?.data.items ?? []).map((device, i) => {
        return {
          index: i + 1,
          deviceId: device.id,
          ...device.fields,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [devicesData.data?.data?.items]
  );
  // console.log(data);

  // // Checkbox for selecting all rows
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  // Define a default UI for filtering
  const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span className="font-bold">
        Search:{" "}
        <input
          type={"text"}
          // autoFocus={true}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Search for Device....`}
          className="mbSc:w-1/2"
          style={{
            fontSize: "1.1rem",
            border: "0",
            outline: "none",
            backgroundColor: "rgba(0, 0, 0, 0.35)",
            color: "black",
            fontWeight: "bold",
            padding: "4px",
          }}
        />
      </span>
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // Search / Filtering Props
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [
          // custId === 1685 ? "bat_voltage" : "",
          custId !== 1685 && "light_power",
          custId === 1685 && "solar_voltage",
          custId === 1685 && "solar_power",
        ],
      },
    },

    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  //console.log(data);

  const formik = useFormik({
    initialValues: {
      brightness: "50",
      duration: "60",
      durationOff: "60",
    },
  });

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(25);

    const title = "Comdelta Technologies Sdn Bhd";
    const headers = [
      { header: "No.", dataKey: "index" },
      { header: "Device ID", dataKey: "lamp_no" },
      { header: "Input Power (W)", dataKey: "bat_voltage" },
      { header: "Road", dataKey: "road_section" },
      custId !== 1685
        ? { header: "Solar Voltage (V)", dataKey: "solar_voltage" }
        : {},
      { header: "PN", dataKey: "module_id" },
      // custId !== 1685
      //   ? { header: "Solar Current (A)", dataKey: "solar_current" }
      //   : {},
      custId !== 1685
        ? { header: "Solar Power (W)", dataKey: "solar_power" }
        : {},
      { header: "Last Update", dataKey: "update_timestamp" },
    ];
    // { header: "Signal Status", dataKey: "signal_status" },
    // { header: "Light", dataKey: "light_brightness" },
    let content = {
      startY: 135,
      columns: headers,
      body: data,
      styles: {
        halign: "center",
        cellPadding: { top: 10, right: 5, bottom: 10, left: 5 },
        fontSize: 12,
      },
    };

    const addFooterCopyRight = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();

      doc.setFont("helvetica", "italic", "bold");
      doc.setFontSize(8);
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        custEmail === "weststar@comdelta.com.my"
          ? doc.text(
              `Copyright © ${new Date().getFullYear()}. All rights reserved.`,
              doc.internal.pageSize.width / 2,
              570,
              {
                align: "center",
              }
            )
          : custEmail === "mbip1-johor23@weststar.com.my"
          ? doc.text(
              `Copyright © ${new Date().getFullYear()}. All rights reserved.`,
              doc.internal.pageSize.width / 2,
              570,
              {
                align: "center",
              }
            )
          : doc.text(
              `Copyright © ${new Date().getFullYear()} Comdelta Technologies Sdn Bhd. All rights reserved.`,
              doc.internal.pageSize.width / 2,
              570,
              {
                align: "center",
              }
            );
        // doc.text(
        //   `Copyright © ${new Date().getFullYear()} Comdelta Technologies Sdn Bhd. All rights reserved.`,
        //   doc.internal.pageSize.width / 2,
        //   570,
        //   {
        //     align: "center",
        //   }
        // );
      }
    };

    const addFooterPageNumber = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();

      doc.setFont("helvetica", "italic");
      doc.setFontSize(8);
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text("Page " + String(i) + " of " + String(pageCount), 40, 570, {
          align: "center",
        });
      }
    };

    custId === 1587
      ? doc.addImage(logoWs, "PNG", 360, 15, 140, 80)
      : doc.addImage(logoPdf, "PNG", 360, 15, 140, 80);

    custEmail === "weststar@comdelta.com.my"
      ? doc.text(custName, marginLeft, 125)
      : custEmail === "mbip1-johor23@weststar.com.my"
      ? doc.text(custName, marginLeft, 125)
      : doc.text(title, marginLeft, 125);

    // custEmailm == "mbip1-johor23@weststar.com.my" ? (): ()
    // doc.text(title, marginLeft, 125);
    doc.autoTable(content);
    addFooterCopyRight(doc);
    addFooterPageNumber(doc);
    doc.save("Devices.pdf");
  };

  const controlLightOn = () => {
    const lightBrightness = formik.values.brightness;
    const lightDuration = formik.values.duration;
    const deviceId = selectedFlatRows.map((d) => d.original.deviceId);
    const deviceName = selectedFlatRows.map((d) => d.original.lamp_no);
    // console.log(deviceId);
    if (!selectedFlatRows.length) {
      return toast.error("Please select a device first");
    } else if (lightDuration < 60 || lightDuration > 54000) {
      return toast.error("Please select a duration between 60 to 54000 ");
    } else {
      toast(
        (t) => (
          <div className="flex flex-col items-center justify-center">
            <p className="text-lg font-bold text-center">You have selected</p>
            {deviceName.map((d, i) => (
              <p className="text-lg font-bold" key={i}>
                {d}
              </p>
            ))}
            <p className="text-lg font-bold text-center">
              with light brightness {lightBrightness} % and duration{" "}
              {lightDuration} seconds
            </p>
            <p className="py-2 text-lg text-center">
              Please check if you have selected the correct device and the
              device has a signal
            </p>
            <div className="py-2">
              <CustButton
                color={"#a11f1f"}
                onClick={() => {
                  controlLightMutation.mutate({
                    projectId: custId,
                    lampIds: deviceId,
                    type: 1,
                    brightness: lightBrightness * 1,
                    time: lightDuration * 1,
                  });
                  toast.success("Proceeding with the request");
                  toast.dismiss(t.id);
                }}
              >
                Yes
              </CustButton>
            </div>
            <div className="py-2">
              <CustButton
                color={"#322cde"}
                onClick={() => {
                  toast.dismiss(t.id);
                }}
              >
                No
              </CustButton>
            </div>
          </div>
        ),
        {
          duration: 60000,
          style: {
            background: "#fff",
            color: "#000",
          },
        }
      );
      // toast.success(
      //   <p className="text-lg font-bold ">
      //     You have selected {deviceId} with light status {lightBrightness}
      //   </p>
      // );
      // lightStatusMutation.mutate({ deviceId, lightBrightness });
    }
  };

  const controlLightOff = () => {
    const lightDuration = formik.values.duration;
    const deviceId = selectedFlatRows.map((d) => d.original.deviceId);
    const deviceName = selectedFlatRows.map((d) => d.original.lamp_no);
    // console.log(selectedFlatRows);
    if (!selectedFlatRows.length) {
      return toast.error("Please select a device first");
    } else if (lightDuration < 60 || lightDuration > 54000) {
      return toast.error("Please select a duration between 60 to 54000 ");
    } else {
      toast(
        (t) => (
          <div className="flex flex-col items-center justify-center">
            <p className="text-lg font-bold">You have selected</p>
            {deviceName.map((d, i) => (
              <p className="text-lg font-bold" key={i}>
                {d}
              </p>
            ))}
            <p className="text-lg font-bold">
              with light duration {lightDuration} seconds
            </p>
            <p className="py-2 text-lg">
              Please check if you have selected the correct device and the
              device has a signal
            </p>
            <div className="py-2">
              <CustButton
                color={"#a11f1f"}
                onClick={() => {
                  controlLightMutation.mutate({
                    projectId: custId,
                    lampIds: deviceId,
                    type: 0,
                    time: lightDuration * 1,
                  });
                  toast.success("Proceeding with the request");
                  toast.dismiss(t.id);
                }}
              >
                Yes
              </CustButton>
            </div>
            <div className="py-2">
              <CustButton
                color={"#322cde"}
                onClick={() => {
                  toast.dismiss(t.id);
                }}
              >
                No
              </CustButton>
            </div>
          </div>
        ),
        {
          duration: 60000,
          style: {
            background: "#fff",
            color: "#000",
          },
        }
      );
      // toast.success(
      //   <p className="text-lg font-bold ">
      //     You have selected {deviceId} with light status {lightBrightness}
      //   </p>
      // );
      // lightStatusMutation.mutate({ deviceId, lightBrightness });
    }
  };

  if (devicesData.isLoading) {
    return (
      <div className="absolute text-xl text-black top-1/2 left-1/2">
        <h1>Loading......</h1>
      </div>
    );
  }

  return (
    <TableContainer>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      {data.length === 0 ? (
        <div className="p-2 text-center">
          <h3>No data found</h3>
        </div>
      ) : (
        <>
          <ActionsContainer align={"space-between"} direction={"column"}>
            <div className="pdfExcelBtnContainer">
              <CustButton color={"#a11f1f"} onClick={() => exportPDF()}>
                Download Pdf
              </CustButton>
            </div>
            {custEmail === "weststar@comdelta.com.my" ||
            custEmail === "tnb@comdelta.com.my" ? (
              <>
                <LightContainer align={"space-between"}>
                  <div className="lightBtnContainer">
                    <CustButton
                      color={"#33cc33"}
                      onClick={() => controlLightOn()}
                    >
                      Light ON
                    </CustButton>
                  </div>
                  <div className="lightControlContainer">
                    <FormikProvider value={formik}>
                      <Form className="flex items-center">
                        <Field
                          id="brightness"
                          type="range"
                          name="brightness"
                          // value="50"
                          min="0"
                          max="100"
                        />
                      </Form>
                    </FormikProvider>
                    <p className="px-10 text-base font-bold ipSc:text-sm">
                      {formik.values.brightness} %
                    </p>
                    <FormikProvider value={formik}>
                      <Form className="flex items-center">
                        <Field
                          id="duration"
                          type="number"
                          name="duration"
                          // value="60"
                          min="60"
                          max="54000"
                        />
                      </Form>
                    </FormikProvider>
                    <p className="px-10 text-lg font-bold ipSc:text-sm">
                      seconds
                    </p>
                  </div>
                </LightContainer>
                <LightContainer align={"space-between"}>
                  <div className="lightBtnContainer">
                    <CustButton
                      color={"#ff0000"}
                      onClick={() => controlLightOff()}
                    >
                      Light OFF
                    </CustButton>
                  </div>
                  <div className="lightControlContainer">
                    <FormikProvider value={formik}>
                      <Form className="flex items-center">
                        <Field
                          id="durationOff"
                          type="number"
                          name="durationOff"
                          // value="60"
                          min="60"
                          max="54000"
                        />
                      </Form>
                    </FormikProvider>
                    <p className="px-10 text-lg font-bold ipSc:text-sm">
                      seconds
                    </p>
                  </div>
                </LightContainer>
              </>
            ) : (
              <></>
            )}
          </ActionsContainer>
          <div className="p-0.5 flex items-center">
            <div className="w-full h-auto">
              <select
                style={{
                  fontSize: "1.1rem",
                  border: "0",
                  outline: "none",
                  backgroundColor: "rgba(0, 0, 0, 0.35)",
                  color: "white",
                  fontWeight: "bold",
                  padding: "2px",
                }}
                className="p-1 m-1"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50]?.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-center w-full h-auto">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>
          </div>

          <TableStyles>
            <table
              className="w-full bg-gray-200 bg-opacity-30"
              {...getTableProps()}
            >
              <thead className="bg-gray-600 divide-y divide-gray-400 bg-opacity-30">
                {headerGroups?.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers?.map((column) => (
                      <th
                        colSpan={visibleColumns.length}
                        scope="col"
                        className="px-6 py-3 text-lg font-bold tracking-wider text-black uppercase"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-gray-300 divide-y divide-gray-200 bg-opacity-60"
                {...getTableBodyProps()}
              >
                {page?.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells?.map((cell) => {
                        // console.log(cell.row.values.deviceId);
                        return (
                          <td
                            className="px-6 py-3 text-lg font-medium tracking-wider text-center text-black "
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* 
              Pagination can be built however you'd like. 
              This is just a very basic UI implementation:
            */}
            <hr className="border-black border-solid" />
            <div className="pagination">
              <div className="w-full text-black">
                <span className="font-bold">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                {/* <span>
                  | Go to page:{" "}
                  <input
                    type={"number"}
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{
                      width: "100px",
                      border: "solid",
                      borderWidth: "2px",
                      outline: "none",
                    }}
                  />
                </span> */}
              </div>{" "}
              <div className="flex flex-row justify-end w-full">
                <button
                  className="flex items-center justify-center w-auto px-1 py-1 text-sm font-medium text-black bg-gray-300 border border-transparent rounded-md bg-opacity-60 hover:bg-gray-600 md:py-2 md:text-base md:px-5"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>{" "}
                <button
                  className="flex items-center justify-center w-auto p-5 px-1 py-1 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md bg-opacity-30 hover:bg-gray-500 md:py-2 md:text-base md:px-5"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>{" "}
                <button
                  className="flex items-center justify-center w-auto p-5 px-1 py-1 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md bg-opacity-30 hover:bg-gray-500 md:py-2 md:text-base md:px-5"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  {">"}
                </button>{" "}
                <button
                  className="flex items-center justify-center w-auto p-5 px-1 py-1 text-sm font-medium text-black bg-gray-300 border border-transparent rounded-md bg-opacity-60 hover:bg-gray-600 md:py-2 md:text-base md:px-5"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>{" "}
            </div>
          </TableStyles>
        </>
      )}
    </TableContainer>
  );
};

export default DashboardDevicesTable;
