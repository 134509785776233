import React, { useState } from "react";

// company logo
import {
  companyLogo1,
  pkfz,
  ws_1,
  ws_3,
  ws_4,
  ws_5,
  ws_6,
  malacca_1,
  mpag_1,
  mpag_2,
  tnb_1,
} from "../assests/Logos";

// styled components
import {
  Nav,
  NavbarContainer,
  SidenavBtnContainer,
  IconBtn,
  UserListBtnContainer,
} from "./StyledComponents";

// font awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import UserList from "./UserList";
import Sidebar from "./Sidebar";
import { useProfileQuery } from "../services/users.service";

const Navbar = () => {
  const [showUserIcon, setShowUserIcon] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const { data } = useProfileQuery();
  // console.log(data);
  return (
    <>
      {data?.data.custId === 1587 ? (
        <Nav bckColor={"#1108df"}>
          <NavbarContainer>
            {data?.data.role === "customer" ? (
              <SidenavBtnContainer
                onClick={() => {
                  setCollapse(!collapse);
                }}
              >
                <IconBtn bckColor={"#1108df"} size={30}>
                  &#9776;
                </IconBtn>
              </SidenavBtnContainer>
            ) : (
              <div></div>
            )}

            {collapse && (
              <Sidebar
                setCllpse={setCollapse}
                cllpse={collapse}
                name={data.data?.name}
                custIdData={data}
              />
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "full",
                  height: "full",
                  padding: "10px 20px 10px 20px",
                }}
                src={ws_4}
                alt="ws4"
              />
              <img
                style={{
                  width: "full",
                  height: "full",
                  padding: "10px 20px 10px 20px",
                }}
                src={ws_1}
                alt="ws1"
              />
              <img
                style={{
                  width: "full",
                  height: "full",
                  padding: "10px 20px 10px 20px",
                }}
                src={ws_3}
                alt="ws3"
              />
              <img
                style={{
                  width: "full",
                  height: "full",
                  padding: "10px 20px 10px 20px",
                }}
                src={ws_6}
                alt="ws6"
              />
              <img
                style={{ width: "500px", height: "96px" }}
                src={ws_5}
                alt="ws5"
              />
            </div>

            <UserListBtnContainer
              onClick={() => setShowUserIcon(!showUserIcon)}
            >
              <IconBtn bckColor={"#1108df"} size={30}>
                <FontAwesomeIcon icon={faUserCircle} />
              </IconBtn>
              {showUserIcon && <UserList role={data} />}
            </UserListBtnContainer>
          </NavbarContainer>
        </Nav>
      ) : (
        <Nav>
          <NavbarContainer>
            {data?.data.role === "customer" || data?.data.role === "admin" ? (
              <SidenavBtnContainer
                onClick={() => {
                  setCollapse(!collapse);
                }}
              >
                <IconBtn size={30}>&#9776;</IconBtn>
              </SidenavBtnContainer>
            ) : (
              <div></div>
            )}

            {collapse && (
              <Sidebar
                setCllpse={setCollapse}
                cllpse={collapse}
                name={data.data?.name}
                custIdData={data}
              />
            )}

            {data?.data.custId === 1545 ? (
              <div>
                <img
                  style={{ width: "176px", height: "96px" }}
                  src={pkfz}
                  alt="PKFZ"
                />
              </div>
            ) : data?.data.custId === 1685 ? (
              <div
                // style={{
                //   display: "flex",
                //   flexDirection: "row",
                //   alignItems: "center",
                //     }}
                className="flex items-center justify-center"
              >
                <img
                  style={{
                    width: "10%",
                    height: "full",
                    padding: "10px 20px 10px 20px",
                  }}
                  src={ws_4}
                  alt="gov"
                />
                <img
                  style={{
                    width: "10%",
                    height: "full",
                    padding: "10px 20px 10px 20px",
                  }}
                  src={malacca_1}
                  alt="gov"
                />
                <img
                  style={{
                    width: "10%",
                    height: "full",
                    padding: "10px 20px 10px 20px",
                  }}
                  src={mpag_1}
                  alt="gov"
                />
                <img
                  style={{
                    width: "full",
                    height: "full",
                    padding: "10px 20px 10px 20px",
                  }}
                  src={mpag_2}
                  alt="gov"
                />
              </div>
            ) : data?.data.email === "tnb@comdelta.com.my" ? (
              <div>
                <img
                  style={{ width: "176px", height: "96px" }}
                  src={tnb_1}
                  alt="Logo"
                />
              </div>
            ) : (
              <div>
                <img
                  // style={{ width: "auto", height: "96px" }}
                  className="w-auto h-24"
                  src={companyLogo1}
                  alt="Logo"
                />
              </div>
            )}

            <UserListBtnContainer
              onClick={() => setShowUserIcon(!showUserIcon)}
            >
              <IconBtn size={30}>
                <FontAwesomeIcon icon={faUserCircle} />
              </IconBtn>
              {showUserIcon && <UserList role={data} />}
            </UserListBtnContainer>
          </NavbarContainer>
        </Nav>
      )}
    </>
  );
};

export default Navbar;
