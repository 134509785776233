// React Router Dom components
import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";

// Styled Components
import {
  ActionsContainer,
  BgContainer,
  CustButton,
  NavBottom,
  NavbarContainerBottom,
  TableContainer,
} from "../components/StyledComponents";

// Navbar component
import Navbar from "../components/Navbar";

// Query functions
// import { useClientDeviceLogsById } from "../services/users.service";
import LEDLogsComponent from "../components/LEDLogsComponent";
import ChargeLogsComponent from "../components/ChargeLogsComponent";
import BatteryLogsComponent from "../components/BatteryLogsComponent";
import SolarLogsComponent from "../components/SolarLogsComponent";
import { ws_2 } from "../assests/Logos";
import {
  useProfileQuery,
  // useDeviceBatteryValueByIdQuery,
} from "../services/users.service";
// import BatteryPercentage from "../components/BatteryPercentageComponent";

// // React Query functions
// import { useProfileQuery } from "../services/users.service";

const DeviceLogs = () => {
  const { deviceName } = useParams();
  const { state } = useLocation();
  const [showLED, setShowLED] = useState(false);
  const [showCharge, setShowCharge] = useState(false);
  const [showBattery, setShowBattery] = useState(false);
  const [showSolar, setShowSolar] = useState(false);
  const [showTitle, setShowTitle] = useState(true);

  const profileQuery = useProfileQuery();
  // const deviceBatteryValueQuery = useDeviceBatteryValueByIdQuery(state.devId);
  // const batteryValue = deviceBatteryValueQuery.data?.data.sysVoltage;
  // console.log(deviceBatteryValueQuery);
  const clientId = profileQuery.data?.data.custId;
  const email = profileQuery.data?.data.email;
  const name = profileQuery.data?.data.name;

  return (
    <BgContainer className="bg-white">
      <Navbar />
      <div className="flex flex-col flex-1 p-1 mx-2 mt-5">
        <TableContainer>
          <ActionsContainer align={"space-between"}>
            {/* <div className="px-2 text-black">
              <h3 style={{ fontSize: "25px" }}>
                Current logs for device: <b>{deviceName}</b>
              </h3>

              <BatteryPercentage
                sysVolt={batteryValue}
                batVolt={state?.batVolt}
              />
            </div> */}
            <div className="pdfExcelBtnContainer">
              <CustButton
                color={"#a6a6a6"}
                onClick={() => {
                  setShowLED(!showLED);
                  setShowCharge(false);
                  setShowBattery(false);
                  setShowSolar(false);
                  setShowTitle(false);
                }}
              >
                LED Logs
              </CustButton>
            </div>
            {clientId !== 1685 ? (
              <div className="pdfExcelBtnContainer">
                <CustButton
                  onClick={() => {
                    setShowCharge(!showCharge);
                    setShowLED(false);
                    setShowBattery(false);
                    setShowSolar(false);
                    setShowTitle(false);
                  }}
                  color={"#a6a6a6"}
                >
                  Charge Logs
                </CustButton>
              </div>
            ) : (
              <></>
            )}
            {clientId !== 1685 ? (
              <div className="pdfExcelBtnContainer">
                <CustButton
                  onClick={() => {
                    setShowBattery(!showBattery);
                    setShowCharge(false);
                    setShowLED(false);
                    setShowSolar(false);
                    setShowTitle(false);
                  }}
                  color={"#a6a6a6"}
                >
                  Battery Logs
                </CustButton>
              </div>
            ) : (
              <></>
            )}
            {clientId !== 1685 ? (
              <div className="pdfExcelBtnContainer">
                <CustButton
                  onClick={() => {
                    setShowSolar(!showSolar);
                    setShowBattery(false);
                    setShowCharge(false);
                    setShowLED(false);

                    setShowTitle(false);
                  }}
                  color={"#a6a6a6"}
                >
                  Solar Panel Logs
                </CustButton>
              </div>
            ) : (
              <></>
            )}
          </ActionsContainer>
          {showTitle && (
            <h1 className="text-center text-black">
              Click one of the above paramters to show the logs
            </h1>
          )}
          {showLED && (
            <LEDLogsComponent
              custId={clientId}
              id={state.devId}
              deviceName={deviceName}
              custEmail={email}
              custName={name}
            />
          )}
          {showCharge && (
            <ChargeLogsComponent
              custId={clientId}
              id={state.devId}
              deviceName={deviceName}
              custEmail={email}
              custName={name}
            />
          )}
          {showBattery && (
            <BatteryLogsComponent
              custId={clientId}
              id={state.devId}
              deviceName={deviceName}
              custEmail={email}
              custName={name}
            />
          )}
          {showSolar && (
            <SolarLogsComponent
              custId={clientId}
              id={state.devId}
              deviceName={deviceName}
              custEmail={email}
              custName={name}
            />
          )}
        </TableContainer>
      </div>
      {clientId === 1587 ? (
        <NavBottom>
          <NavbarContainerBottom>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "176px",
                  height: "96px",
                  padding: "15px 10px 5px 10px",
                }}
                src={ws_2}
                alt="ws2"
              />
            </div>
            {email === "weststar@comdelta.com.my" ? (
              <p>Powered by Comdelta Technologies Sdn Bhd</p>
            ) : (
              <></>
            )}
          </NavbarContainerBottom>
        </NavBottom>
      ) : (
        <></>
      )}
    </BgContainer>
  );
};

export default DeviceLogs;
