import React from "react";
import {
  TitleH2,
  SubTitleP,
  ContainerButton,
  LinkButton,
  ComProfLink,
  DivLink,
  CopyrightText,
  ContentBox,
  BgContainerImage,
} from "../components/StyledComponents";

// New Tab Function
import { openInNewTab } from "../js/helpers";

// Logo
import MainLogo from "../components/MainLogo";

// background pic
import background from "./../assests/bg1.jpeg";

const Home = () => {
  return (
    <BgContainerImage background={background}>
      <div>
        <ContentBox>
          <MainLogo />

          <TitleH2>
            <SubTitleP size={25}>
              Welcome to Comdelta Street Light Monitoring System
            </SubTitleP>
          </TitleH2>

          <TitleH2 size={20} color={"yellow"}>
            Please login to monitor your devices
          </TitleH2>

          <ContainerButton>
            <LinkButton to="/login" color={"#000000"}>
              Login
            </LinkButton>
          </ContainerButton>

          <DivLink>
            <ComProfLink
              onClick={() => openInNewTab("https://www.comdelta.com.my")}
            >
              Click Me ;) to visit our company profile to view all the products
            </ComProfLink>
          </DivLink>

          <CopyrightText>
            Copyright &copy;{new Date().getFullYear()}{" "}
            <b> Comdelta Technology Sdn Bhd. </b> All rights reserved.
          </CopyrightText>
        </ContentBox>
      </div>
    </BgContainerImage>
  );
};

export default Home;
