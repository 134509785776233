import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import {
  BgContainer,
  DHNameContainer,
  DashboardHeaderContainer,
} from "../components/StyledComponents";
import {
  useClientDevicesLocationDataByIdQuery,
  useProfileQuery,
} from "../services/users.service";

import MapComponent from "../components/MapComponent";

// const MapComponent = React.lazy(() => import("../components/MapComponent"));

const ModeratorMap = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const profileQuery = useProfileQuery();

  const clientDevicesLocationDataQuery =
    useClientDevicesLocationDataByIdQuery(id);

  // const devicesDetails = clientDevicesLocationDataQuery.data?.data;
  // console.log(devicesDetails);

  // const details = devicesDataQuery.data?.data;
  // console.log(devicesDetails);
  if (profileQuery.isLoading || clientDevicesLocationDataQuery.isLoading) {
    return (
      <div className="absolute text-xl text-black top-1/2 left-1/2">
        <h1>Loading......</h1>
      </div>
    );
  }

  return (
    <BgContainer className="bg-white">
      <Navbar />
      <DashboardHeaderContainer>
        <DHNameContainer>
          <h1>{profileQuery.data?.data.name}</h1>
          <h2>Project Name: {name}</h2>
        </DHNameContainer>
      </DashboardHeaderContainer>
      <div className="px-2 pt-3 pb-5 mt-3 bg-gray-400 border-2 border-solid">
        <MapComponent devicesDetails={clientDevicesLocationDataQuery} />
      </div>
    </BgContainer>
  );
};

export default ModeratorMap;
