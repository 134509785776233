// React Component
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
//import { Link } from "react-router-dom";

// Navbar component
import Navbar from "../components/Navbar";

// Styled Components
import {
  BgContainer,
  DashboardBodyContainer,
  DashboardHeaderContainer,
  DashboardPageCountainer,
  DHBarContainer,
  DHBSubContainer,
  DHNameContainer,
} from "../components/StyledComponents";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleGroup,
  faPowerOff,
  faTablet,
} from "@fortawesome/free-solid-svg-icons";

// Circule bar component
import CounterCircleBar from "../components/CounterCircleBar";

// Table component
import DashboardDevicesTable from "../components/DashboardDevicesTable";

// React Query functions
import {
  useClientDevicesDataQuery,
  useProfileQuery,
} from "../services/users.service";

// const MapDashboard = React.lazy(() => import("../components/MapDashboard"));

const AdminDevices = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const profileQuery = useProfileQuery();

  const clientDevicesDataQuery = useClientDevicesDataQuery(id);

  const totalDevices = clientDevicesDataQuery.data?.data?.items
    ? clientDevicesDataQuery.data?.data.items.length
    : 0;

  const activeDevices = clientDevicesDataQuery.data?.data?.items
    ? clientDevicesDataQuery.data?.data.items.filter((device) => {
        return device.fields.signal_status !== "0";
      }).length
    : 0;
  const inactiveDevices =
    totalDevices - activeDevices ? totalDevices - activeDevices : 0;

  return (
    <BgContainer>
      <Navbar />
      <DashboardPageCountainer>
        <DashboardHeaderContainer>
          <DHNameContainer>
            <h1>{profileQuery.data?.data.name}</h1>
            <h2>Project Name: {name}</h2>
          </DHNameContainer>
          <DHBarContainer>
            <DHBSubContainer>
              <div className="hidden">
                <CounterCircleBar
                  end="100"
                  rgb="rgba(51, 102, 255, 1)"
                  label="Clients"
                  num={10}
                  iconColor={"rgb(128, 159, 255)"}
                  icon={<FontAwesomeIcon icon={faPeopleGroup} />}
                />
              </div>

              <div>
                <CounterCircleBar
                  end="100"
                  rgb="rgba(51, 204, 255, 1)"
                  label="Devices"
                  num={totalDevices}
                  iconColor={"rgb(128, 223, 255)"}
                  icon={<FontAwesomeIcon icon={faTablet} />}
                />
              </div>
            </DHBSubContainer>

            <DHBSubContainer>
              <div>
                <CounterCircleBar
                  end="100"
                  rgb="rgba(51, 204, 51, 1)"
                  label="Active"
                  num={activeDevices}
                  iconColor={"rgb(0, 255, 0)"}
                  icon={<FontAwesomeIcon icon={faPowerOff} />}
                />
              </div>
              <div>
                <CounterCircleBar
                  end="0"
                  rgb="rgba(255, 0, 0, 1)"
                  label="Inactive"
                  num={inactiveDevices}
                  iconColor={"rgb(255, 77, 77)"}
                  icon={<FontAwesomeIcon icon={faPowerOff} />}
                />
              </div>
            </DHBSubContainer>
          </DHBarContainer>
        </DashboardHeaderContainer>

        <DashboardBodyContainer>
          <DashboardDevicesTable
            devicesData={clientDevicesDataQuery}
            custId={id}
          />
        </DashboardBodyContainer>
      </DashboardPageCountainer>
    </BgContainer>
  );
};

export default AdminDevices;
