import React from "react";
import { Navigate } from "react-router-dom";
import { useProfileQuery } from "../services/users.service";

export const RouteGuard = ({ children, role, redirect }) => {
  const { data, isLoading } = useProfileQuery();

  if (isLoading) return null;

  if (data?.data) {
    if (role) {
      if (role.includes(data.data.role)) {
        return children;
      } else {
        return <Navigate to="/" />;
      }
    } else {
      return children;
    }
  }

  return <Navigate to={redirect} />;
};

export const RouteGuardLogin = ({ children }) => {
  const { data, isLoading } = useProfileQuery();

  if (isLoading) return null;

  if (data?.data) {
    if (data?.data.role === "admin") {
      return <Navigate to="/adminDashboard" />;
    } else if (data?.data.role === "moderator") {
      return <Navigate to="/moderatorDashboard" />;
    } else {
      return <Navigate to="/dashboard" />;
    }
  } else {
    return children;
  }
};
