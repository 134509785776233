import { easeQuadInOut } from "d3-ease";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import AnimatedProgressProvider from "./AnimatedProgress";
import CountUp from "react-countup";

const CounterCircleBar = ({ end, rgb, icon, num, label, iconColor }) => {
  return (
    <div>
      {/* <!--Metric Card--> */}
      <div style={{ padding: "8px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "170px",
          }}
        >
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={end}
            duration={1.4}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              return (
                <CircularProgressbarWithChildren
                  value={value}
                  /* This is important to include, because if you're fully managing the
                              animation yourself, you'll want to disable the CSS animation. */
                  styles={buildStyles({
                    pathTransition: "none",
                    pathColor: rgb,
                  })}
                >
                  <span style={{ color: `${iconColor}`, fontSize: "30px" }}>
                    {icon}
                  </span>
                  <h5
                    style={{
                      fontSize: "18px",
                      margin: "10px",
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "black",
                      textTransform: "uppercase",
                    }}
                  >
                    {label}
                  </h5>
                  <h3
                    style={{
                      fontSize: "20px",
                      margin: "5px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <CountUp start={0} end={num} delay={0} duration={1.4}>
                      {({ countUpRef }) => (
                        <div>
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  </h3>
                </CircularProgressbarWithChildren>
              );
            }}
          </AnimatedProgressProvider>
        </div>
      </div>
      {/* <!--/Metric Card--> */}
    </div>
  );
};

export default CounterCircleBar;
