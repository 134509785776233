import React, { useRef, useEffect, useState, useMemo } from "react";

// Map compoenent
import mapboxgl from "mapbox-gl";

// Styled component
// import { MapBoxContainer } from "./StyledComponents";

// Map token
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

const MapComponent = ({ devicesDetails }) => {
  const mapContainer = useRef(null);

  const [lng, setLng] = useState(109.3975);
  const [lat, setLat] = useState(3.8592);
  const [zoom, setZoom] = useState(5.39);

  // const details = devicesDataQuery.data?.data;
  // console.log(details);
  const devicesData = devicesDetails.data?.data;

  const data = useMemo(
    () =>
      (devicesData ?? []).map((device) => {
        return {
          ...device,
          updateTime: new Date(device.updateTime).toLocaleString(),
          latitude: device.latitude ? device.latitude : "0.0",
          longitude: device.longitude ? device.longitude : "0.0",
        };
      }),
    [devicesData]
  );
  // console.log(data);
  //   console.log(data[5]?.latitude);

  const geoJson = {
    features: data?.map((device) => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(device.longitude),
            parseFloat(device.latitude),
          ],
        },
        properties: {
          updateTime: device.updateTime,

          brightness: device.brightness,

          chargingStatus: device.chargingStatus,

          lampNo: device.lampNo,

          signal: device.signal,

          warmMsgDto: device.warmMsgDto,
        },
      };
    }),
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    // const marker = new mapboxgl.Marker({
    //   color: "#000000",
    //   draggable: false,
    // }).setLngLat([101.6027, 2.9445]);

    // marker.addTo(map);

    // // Create default markers
    const markers = geoJson.features.map(({ geometry, properties }) => {
      let color = "gray";
      if (properties.signal !== 0) {
        color = "green";
      }

      let signal;
      if (properties.signal === 0) {
        signal = "No Signal";
      } else if (properties.signal >= 1 && properties.signal <= 25) {
        signal = "Poor Signal";
      } else if (properties.signal >= 26 && properties.signal <= 45) {
        signal = "Average Signal";
      } else if (properties.signal >= 46 && properties.signal <= 65) {
        signal = "Good Signal";
      } else if (properties.signal >= 66 && properties.signal <= 100) {
        signal = "Full Signal";
      } else {
        signal = <p>No Signal</p>;
      }

      return new mapboxgl.Marker({ color: color })
        .setLngLat(geometry.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25, className: "listUiStyle" }).setHTML(
            `
            <div>
                <h2>Device ID:${properties.lampNo}</h2>
                <h2>Signal: ${signal}</h2>
                <h2>Brightness: ${properties.brightness}</h2>
                <h2>Charging Status: ${properties.chargingStatus}</h2>
                <h2>Error Type : ${
                  properties.warmMsgDto === "-"
                    ? "Normal"
                    : properties.warmMsgDto
                }
                </h2>
            </div>
            <h2>Update Time : ${properties.updateTime}</h2>
            `
          )
        );
    });
    markers.forEach((marker) => marker.addTo(map));
    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    map.on("move", () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

    // Clean up on unmount
    return () => map.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full pb-10 ">
      <div>
        <div className="sidebar">
          Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
        <div ref={mapContainer} className="map-container" />
      </div>
    </div>
  );
};

export default MapComponent;
