import React, { useMemo } from "react";

// styled components
import {
  BgContainer,
  FormBtn,
  FormBtnContainer,
  FormContentContainer,
  FormPageCountainer,
} from "../components/StyledComponents";

// form package
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// Form label and input box
import { FormElements, SelectOptionsField } from "../components/FormElements";

// NavBar component
import Navbar from "../components/Navbar";

// Add Client mutation function
import { useAddClientsMutation } from "../services/auth.service";
import { useModeratorsDataQuery } from "../services/users.service";
// Toast notification
import toast, { Toaster } from "react-hot-toast";

// Status Code
import { StatusCodes } from "http-status-codes";
import { accountsRole } from "../js/constants";

const SignUp = () => {
  const moderatorsDataQuery = useModeratorsDataQuery();
  // console.log(moderatorsDataQuery.data?.data);

  const data = useMemo(
    () =>
      (moderatorsDataQuery.data?.data ?? []).map((moderator) => {
        return {
          label: moderator.name,
          value: moderator.moderatorId,
        };
      }),
    [moderatorsDataQuery.data?.data]
  );

  const fullData = [{ label: "Select Moderator", value: "" }, ...data];

  const addClientsMutation = useAddClientsMutation({
    onSuccess: () => {
      formik.resetForm({ values: "" });

      // complete submission
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err.response) {
        if (err.response.status === StatusCodes.BAD_REQUEST) {
          const { message } = err.response.data;

          if (message.includes("empty")) {
            formik.setFieldError("email", message);
            formik.setFieldError("password", message);
            toast.error(message);
          } else if (message.includes("name")) {
            formik.setFieldError("name", message);
            toast.error(message);
          } else if (message.includes("Invalid")) {
            formik.setFieldError("email", message);
            toast.error(message);
          } else if (message.includes("Password")) {
            formik.setFieldError("password", message);
            toast.error(message);
          } else if (message.includes("Passwords")) {
            formik.setFieldError("password", message);
            toast.error(message);
          } else if (message.includes("existed")) {
            formik.setFieldError("email", message);
            toast.error(message);
          } else {
            toast.error(message);
          }
        }
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      custId: "",
      role: "",
      moderatedBy: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
      password: Yup.string()
        .min(8, "Must be 8 characters or more")
        .required("Password is Required"),
      confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      custId: Yup.string().required("Customer Id is Required"),
      role: Yup.string().required("Role is Required"),
    }),
    onSubmit: (values) => {
      addClientsMutation.mutate(values);
      // console.log(values);
    },
  });

  // useEffect(() => {
  //   if (data) {
  //     formik.setValues({
  //       ...data,
  //       name: "",
  //       email: "",
  //       password: "",
  //       confirmPassword: "",
  //       custId: "",
  //       role: "",
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  if (moderatorsDataQuery.isLoading) {
    return (
      <div className="absolute text-xl text-black top-1/2 left-1/2">
        <h1>Loading......</h1>
      </div>
    );
  }

  return (
    <BgContainer>
      <Navbar />

      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>

      <FormPageCountainer>
        <FormContentContainer>
          <div>
            <h2>Register New Account</h2>
          </div>
          <FormikProvider value={formik}>
            <Form>
              <FormElements
                id="name"
                label="Name"
                name="name"
                type="text"
                placeholder="Enter your name"
              />

              <FormElements
                id="email"
                label="Email"
                name="email"
                type="email"
                placeholder="Enter your email"
              />

              <FormElements
                id="password"
                label="Password"
                name="password"
                type="password"
                placeholder="Enter your password"
              />

              <FormElements
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                placeholder="Confirm your password"
              />

              <FormElements
                id="custId"
                label="Customer ID"
                name="custId"
                type="text"
                placeholder="Enter your customer ID"
              />

              <SelectOptionsField
                id="role"
                label="Role"
                name="role"
                options={accountsRole}
              />

              {formik.values.role === "customer" && (
                <SelectOptionsField
                  id="moderatedBy"
                  label="Moderated By"
                  name="moderatedBy"
                  options={fullData}
                />
              )}

              {!formik.isSubmitting && (
                <FormBtnContainer>
                  <FormBtn type="submit">Register</FormBtn>
                </FormBtnContainer>
              )}

              {formik.isSubmitting && (
                <FormBtnContainer>
                  <FormBtn type="submit">Register</FormBtn>
                </FormBtnContainer>
              )}
            </Form>
          </FormikProvider>
        </FormContentContainer>
      </FormPageCountainer>
    </BgContainer>
  );
};

export default SignUp;
