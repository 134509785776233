import React from "react";
// Styled Components
import { LogoContainer, LogoTag } from "../components/StyledComponents";

// Logos
import { companyLogo } from "../assests/Logos";

const MainLogo = () => {
  return (
    <LogoContainer>
      <LogoTag src={companyLogo} alt="logo" />
    </LogoContainer>
  );
};

export default MainLogo;
