import { useMutation, useQueryClient } from "react-query";
//
import { axiosInstance } from "../js/axiosInstance";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// API
const API_URL = process.env.REACT_APP_API_KEY;
const optionsHeader = {
  headers: {
    Accept: "application/json",
    channel: "0",
    i18n: "en_US",
    sign: process.env.REACT_APP_SIGN_KEY,
    token: process.env.REACT_APP_TOKEN_KEY,
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
};

const SERVER_URL = process.env.REACT_APP_API_START_POINT;

const login = async (values) => {
  const res = await axiosInstance.post(`${SERVER_URL}auth/login`, values);
  return res.data;
};
export function useLoginMutation(options) {
  const navigate = useNavigate();

  return useMutation(login, {
    onSettled: (data) => {
      if (data?.message === "Login Successful") {
        if (data?.role === "customer") {
          return navigate("/dashboard", { replace: true });
        } else if (data?.role === "admin") {
          return navigate("/adminDashboard", { replace: true });
        } else if (data?.role === "moderator") {
          return navigate("/moderatorDashboard", { replace: true });
        } else {
          return navigate("/login", { replace: true });
        }
      }
    },
    ...options,
  });
}

const addClients = async (values) => {
  const res = await axiosInstance.post(`${SERVER_URL}auth/addClients`, {
    ...values,
    // joinDate: new Date(values.joinDate).toUTCString(),
  });
  return res.data;
};
export function useAddClientsMutation(options) {
  return useMutation(addClients, {
    onSettled: (data) => {
      if (data?.message === "User has been registered seccessfuly")
        toast.success("User has been registered seccessfuly");
    },
    ...options,
  });
}

const controlLight = async (values) => {
  const res = await axios.post(
    `${API_URL}lampControl/switch`,
    {
      ...values,
      // joinDate: new Date(values.joinDate).toUTCString(),
    },
    optionsHeader
  );
  // console.log(res);
  return res.data;
};
export function useControlLightMutation(options) {
  return useMutation(controlLight, {
    onSettled: (data) => {
      if (data?.code === 0)
        setTimeout(() => {
          toast.success("Request has been delivered");
        }, 5500);
    },
    ...options,
  });
}

const editAccount = async ({ values, id }) => {
  const res = await axiosInstance.put(`${SERVER_URL}auth/editAccount/${id}`, {
    ...values,
    // joinDate: new Date(values.joinDate).toUTCString(),
  });
  return res.data;
};
export function useEditAccountMutation(options) {
  const navigate = useNavigate();

  return useMutation(editAccount, {
    onSettled: (data) => {
      const { message } = data;
      if (data?.role === "customer") {
        toast.success(message);
        setTimeout(() => {
          navigate("/adminDashboard", { replace: true });
        }, 2750);
      } else {
        toast.success(message);
        setTimeout(() => {
          navigate("/manageUsers", { replace: true });
        }, 2750);
      }
    },
    ...options,
  });
}

// const deleteClient = async ({ id }) => {
//   const res = await axiosInstance.delete(`${API_URL}auth/deleteClient/${id}`);
//   return res.data;
// };
// export function useDeleteClientMutation(options) {
//   return useMutation(deleteClient, {
//     onSettled: (data) => {
//       if (data?.message === "Client has been deleted seccessfuly")
//         toast.success("Client has been deleted seccessfuly");
//     },
//     ...options,
//   });
// }

// const addUsers = async (values) => {
//   const res = await axiosInstance.post(`${API_URL}auth/addUsers`, {
//     ...values,
//     joinDate: new Date(values.joinDate).toUTCString(),
//   });
//   return res.data;
// };
// export function useAddUsersMutation(options) {
//   return useMutation(addUsers, {
//     onSettled: (data) => {
//       if (data?.message === "User has been registered seccessfuly")
//         toast.success("User has been registered seccessfuly");
//     },
//     ...options,
//   });
// }

// const editUser = async ({ values, id }) => {
//   const res = await axiosInstance.put(`${API_URL}auth/editUser/${id}`, {
//     ...values,
//     joinDate: new Date(values.joinDate).toUTCString(),
//   });
//   return res.data;
// };
// export function useEditUserMutation(options) {
//   return useMutation(editUser, {
//     onSettled: (data) => {
//       if (data?.message === "User has been updated seccessfuly")
//         toast.success("User has been updated seccessfuly");
//     },
//     ...options,
//   });
// }

// const deleteUser = async ({ id }) => {
//   const res = await axiosInstance.delete(`${API_URL}auth/deleteUser/${id}`);
//   return res.data;
// };
// export function useDeleteUserMutation(options) {
//   return useMutation(deleteUser, {
//     onSettled: (data) => {
//       if (data?.message === "User has been deleted seccessfuly")
//         toast.success("User has been deleted seccessfuly");
//     },
//     ...options,
//   });
// }

// const addDevices = async (values) => {
//   const res = await axiosInstance.post(`${API_URL}auth/addDevices`, {
//     ...values,
//     installationDate: new Date(values.installationDate).toUTCString(),
//   });
//   return res.data;
// };
// export function useAddDevicesMutation(options) {
//   return useMutation(addDevices, {
//     onSettled: (data) => {
//       if (data?.message === "Device has been registered seccessfuly")
//         toast.success("Device has been registered seccessfuly");
//       toast.success(`${data.message}`);
//     },
//     ...options,
//   });
// }

// const editDevice = async ({ values, id }) => {
//   const res = await axiosInstance.put(`${API_URL}auth/editDevice/${id}`, {
//     ...values,
//     installationDate: new Date(values.installationDate).toUTCString(),
//   });
//   return res.data;
// };
// export function useEditDeviceMutation(options) {
//   const navigate = useNavigate();

//   return useMutation(editDevice, {
//     onSettled: (data) => {
//       if (data?.message === "Device has been updated seccessfuly")
//         toast.success("Device has been updated seccessfuly");
//         navigate("/manageDevices", { replace: true })
//     },
//     ...options,
//   });
// }

// const deleteDevice = async ({ id }) => {
//   const res = await axiosInstance.delete(`${API_URL}auth/deleteDevice/${id}`);
//   return res.data;
// };
// export function useDeleteDeviceMutation(options) {
//   return useMutation(deleteDevice, {
//     onSettled: (data) => {
//       if (data?.message === "Device has been deleted seccessfuly")
//         toast.success("Device has been deleted seccessfuly");
//     },
//     ...options,
//   });
// }

// const lightStatus = async ({ deviceId, lightStage }) => {
//   const res = await axiosInstance.put(`${API_URL}auth/lightStatus`, {
//     deviceId,
//     lightStage,
//   });
//   return res.data;
// };
// export function useLightStatusMutation(options) {
//   return useMutation(lightStatus, {
//     onSettled: (data) => {
//       if (data?.message === "Light has been updated seccessfuly")
//         toast.success(
//           <p className="text-lg font-bold ">
//             Light has been updated seccessfuly
//           </p>
//         );
//       setTimeout(() => {
//         return toast.success(
//           <p className="text-lg font-bold ">Light brightness changed</p>
//         );
//       }, 5000);
//     },
//     ...options,
//   });
// }

const logout = async () => {
  const res = await axiosInstance.post(`${SERVER_URL}auth/logout`);
  return res.data;
};
export function useLogoutMutation() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(logout, {
    onSettled: () => {
      navigate("/", { replace: true });
      queryClient.removeQueries();
      localStorage.clear();
    },
  });
}
