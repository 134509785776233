import { useQuery } from "react-query";

// Axios instance
import { axiosInstance } from "../js/axiosInstance";
import axios from "axios";

import toast from "react-hot-toast";
//const API_URL = "http://103.18.247.174:8001/"; http://localhost:8080/

// API
const API_URL = process.env.REACT_APP_API_KEY;
const options = {
  headers: {
    Accept: "application/json",
    channel: "0",
    i18n: "en_US",
    sign: process.env.REACT_APP_SIGN_KEY,
    token: process.env.REACT_APP_TOKEN_KEY,
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
};

const SERVER_URL = process.env.REACT_APP_API_START_POINT;

export const usersQueryKeys = {
  all: ["users"],
  profile: () => [...usersQueryKeys.all, "custId"],
  usersData: () => [...usersQueryKeys.all, "usersData"],
  accountDataById: (id) => [...usersQueryKeys.all, "accountDataById", id],
  customersData: () => [...usersQueryKeys.all, "customersData"],
  moderatorsData: () => [...usersQueryKeys.all, "moderatorsData"],
  moderatedByDataById: (id) => [
    ...usersQueryKeys.all,
    "moderatedByDataById",
    id,
  ],
  clientDevicesDataById: () => [...usersQueryKeys.all, "clientDevicesDataById"],
  clientLEDLogsHistoryById: (id) => [
    ...usersQueryKeys.all,
    "clientLEDLogsHistoryById",
    id,
  ],
  clientChargeLogsHistoryById: (id) => [
    ...usersQueryKeys.all,
    "clientChargeLogsHistoryById",
    id,
  ],
  clientBatteryLogsHistoryById: (id) => [
    ...usersQueryKeys.all,
    "clientBatteryLogsHistoryById",
    id,
  ],
  clientSolarLogsHistoryById: (id) => [
    ...usersQueryKeys.all,
    "clientSolarLogsHistoryById",
    id,
  ],
  alarmLogsHistoryByClient: () => [
    ...usersQueryKeys.all,
    "alarmLogsHistoryByClientId",
  ],
  clientDataById: (id) => [...usersQueryKeys.all, "clientDataById", id],
  clientDevicesLocationDataById: (id) => [
    ...usersQueryKeys.all,
    "clientDevicesLocationDataById",
    id,
  ],
  deviceBatteryValueById: (id) => [
    ...usersQueryKeys.all,
    "deviceBatteryValueById",
    id,
  ],
  //   clientsData: () => [...usersQueryKeys.all, "clientsData"],
  //   clientDataId: (id) => [...usersQueryKeys.clientsData(), id],
  //   usersData: () => [...usersQueryKeys.all, "usersData"],
  //   userDataId: (id) => [...usersQueryKeys.usersData(), id],
  //   // clientDevicesData: () => [...usersQueryKeys.all, "devicesData"],
  //   deviceData: (id) => [...usersQueryKeys.clientDevicesData(), id],
  //   deviceLogs: () => [...usersQueryKeys.all, "deviceLogs"],
  //   deviceLogsId: (id) => [...usersQueryKeys.deviceLogs(), id],
};

export const useProfileQuery = () => {
  return useQuery(
    usersQueryKeys.profile(),
    () =>
      axiosInstance.get(`${SERVER_URL}profile`).then((res) => {
        // console.log(res.data);
        return res.data;
      }),
    {
      onError: (err) => {
        toast.error("Please login again");
        // console.log(err.response.data.message);
      },
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
};

export const useUsersDataQuery = () => {
  return useQuery(
    usersQueryKeys.usersData(),
    () =>
      axiosInstance.get(`${SERVER_URL}users`).then((res) => {
        // console.log(res.data);
        return res.data;
      }),
    {
      onError: (err) => {
        toast.error(err);
        // console.log(err.response.data.message);
      },
      // staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAccountDataByIdQuery = (id) => {
  return useQuery(
    usersQueryKeys.accountDataById(id),
    () =>
      axiosInstance.get(`${SERVER_URL}accountData/${id}`).then((res) => {
        // console.log(res.data);
        return res.data;
      }),
    {
      onError: (err) => {
        toast.error(err);
        // console.log(err.response.data.message);
      },
      // staleTime: 60000,
      // refetchOnWindowFocus: false,
    }
  );
};

export const useCustomersDataQuery = () => {
  return useQuery(
    usersQueryKeys.customersData(),
    () =>
      axiosInstance.get(`${SERVER_URL}customers`).then((res) => {
        // console.log(res.data);
        return res.data;
      }),
    {
      onError: (err) => {
        toast.error(err);
        // console.log(err.response.data.message);
      },
      // staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
};

export const useModeratorsDataQuery = () => {
  return useQuery(
    usersQueryKeys.moderatorsData(),
    () =>
      axiosInstance.get(`${SERVER_URL}moderators`).then((res) => {
        // console.log(res.data);
        return res.data;
      }),
    {
      onError: (err) => {
        toast.error(err);
        // console.log(err.response.data.message);
      },
      // staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
};

export const useModeratedByDataQueryById = (id) => {
  return useQuery(
    usersQueryKeys.moderatedByDataById(id),
    () =>
      axiosInstance.get(`${SERVER_URL}moderatedBy/${id}`).then((res) => {
        // console.log(res.data);
        return res.data;
      }),
    {
      onError: (err) => {
        toast.error(err);
        // console.log(err.response.data.message);
      },
      // staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
};

export const useClientDevicesDataQuery = (id) => {
  return useQuery(
    usersQueryKeys.clientDevicesDataById(id),
    () =>
      axios
        .get(`${API_URL}lampControls?projectId=${id}&pageSize=200`, options)
        .then((res) => {
          // console.log(res.data);
          return res.data;
        }),
    {
      onError: (err) => {
        console.log(err);
      },
      staleTime: 60000,
    }
  );
};

export const useClientLEDLogsHistoryByIdQuery = (id, sDate, eDate) => {
  return useQuery(
    usersQueryKeys.clientLEDLogsHistoryById(id),
    () =>
      axios
        .get(
          `${API_URL}lampControl/historyCurve?id=${id}&type=0&sDate=${sDate}&eDate=${eDate}`,
          options
        )
        .then((res) => {
          // console.log(res.data);
          return res.data;
        }),
    {
      onError: (err) => {
        console.log(err);
      },
      retry: 0,
      staleTime: 60000,
      // refetchOnMount: true,
      // refetchInterval: 2000,
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
};

export const useClientChargeLogsHistoryByIdQuery = (id, sDate, eDate) => {
  return useQuery(
    usersQueryKeys.clientChargeLogsHistoryById(id),
    () =>
      axios
        .get(
          `${API_URL}lampControl/historyCurve?id=${id}&type=1&sDate=${sDate}&eDate=${eDate}`,
          options
        )
        .then((res) => {
          // console.log(res.data);
          return res.data;
        }),
    {
      onError: (err) => {
        console.log(err);
      },
      retry: 0,
      staleTime: 60000,
      // refetchOnMount: true,
      // refetchInterval: 2000,
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
};

export const useClientBatteryLogsHistoryByIdQuery = (id, sDate, eDate) => {
  return useQuery(
    usersQueryKeys.clientBatteryLogsHistoryById(id),
    () =>
      axios
        .get(
          `${API_URL}lampControl/historyCurve?id=${id}&type=2&sDate=${sDate}&eDate=${eDate}`,
          options
        )
        .then((res) => {
          // console.log(res.data);
          return res.data;
        }),
    {
      onError: (err) => {
        console.log(err);
      },
      retry: 0,
      staleTime: 60000,
      // refetchOnMount: true,
      // refetchInterval: 2000,
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
};

export const useClientSolarLogsHistoryByIdQuery = (id, sDate, eDate) => {
  return useQuery(
    usersQueryKeys.clientSolarLogsHistoryById(id),
    () =>
      axios
        .get(
          `${API_URL}lampControl/historyCurve?id=${id}&type=3&sDate=${sDate}&eDate=${eDate}`,
          options
        )
        .then((res) => {
          // console.log(res.data);
          return res.data;
        }),
    {
      onError: (err) => {
        console.log(err);
      },
      retry: 0,
      staleTime: 60000,
      // refetchOnMount: true,
      // refetchInterval: 2000,
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
};

export const useAlarmLogsHistoryByClientQuery = (sDate, eDate) => {
  return useQuery(
    usersQueryKeys.alarmLogsHistoryByClient(),
    () =>
      axios
        .get(
          `${API_URL}warnings?sdate=${sDate}+00:00:00&edate=${eDate}+23:59:00&page=1&pagesize=50&companyId=149`,
          options
        )
        .then((res) => {
          // console.log(res.data);
          return res.data;
        }),
    {
      onError: (err) => {
        console.log(err);
      },
      retry: 0,
      staleTime: 60000,
      // refetchOnMount: true,
      // refetchInterval: 2000,
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
};

export const useClientDataByIdQuery = (id) => {
  return useQuery(
    usersQueryKeys.clientDataById(id),
    () =>
      axios.get(`${API_URL}project/${id}`, options).then((res) => {
        // console.log(res.data);
        return res.data;
      }),
    {
      onError: (err) => {
        console.log(err);
      },
      retry: 0,
      staleTime: 60000,
      // refetchOnMount: true,
      // refetchInterval: 2000,
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
};

export const useDeviceBatteryValueByIdQuery = (id) => {
  return useQuery(
    usersQueryKeys.deviceBatteryValueById(id),
    () =>
      axios
        .get(`${API_URL}lampControl/batteryCtrl?id=${id}`, options)
        .then((res) => {
          // console.log(res.data);
          return res.data;
        }),
    {
      onError: (err) => {
        console.log(err);
      },
      retry: 0,
      staleTime: 60000,
      // refetchOnMount: true,
      // refetchInterval: 2000,
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
};

export const useClientDevicesLocationDataByIdQuery = (id) => {
  return useQuery(
    usersQueryKeys.clientDevicesLocationDataById(id),
    () =>
      axios.get(`${API_URL}gisLamps?projectId=${id}`, options).then((res) => {
        // console.log(res.data);
        return res.data;
      }),
    {
      onError: (err) => {
        console.log(err);
      },
      retry: 0,
      staleTime: 60000,
      // refetchOnMount: true,
      // refetchInterval: 2000,
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
};

// export const useClientsDataQuery = () => {
//   return useQuery(
//     usersQueryKeys.clientsData(),
//     () =>
//       axiosInstance.get(`${API_URL}manage/clientsData`).then((res) => {
//         // console.log(res.data);
//         return res.data;
//       }),
//     {
//       onError: (err) => {
//         console.log(err);
//       },
//       staleTime: 0,
//     }
//   );
// };

// export const useClientsDataIdQuery = ({ id }) => {
//   return useQuery(
//     usersQueryKeys.clientDataId(id),
//     () =>
//       axiosInstance.get(`${API_URL}manage/clientData/${id}`).then((res) => {
//         // console.log(res.data);
//         return res.data;
//       }),
//     {
//       onError: (err) => {
//         console.log(err);
//       },
//       staleTime: 0,
//     }
//   );
// };

// export const useUsersDataQuery = () => {
//   return useQuery(
//     usersQueryKeys.usersData(),
//     () =>
//       axiosInstance.get(`${API_URL}manage/usersData`).then((res) => {
//         // console.log(res.data);
//         return res.data;
//       }),
//     {
//       onError: (err) => {
//         console.log(err);
//       },
//       staleTime: 0,
//     }
//   );
// };

// export const useUserDataIdQuery = ({ id }) => {
//   return useQuery(
//     usersQueryKeys.userDataId(id),
//     () =>
//       axiosInstance.get(`${API_URL}manage/userData/${id}`).then((res) => {
//         // console.log(res.data);
//         return res.data;
//       }),
//     {
//       onError: (err) => {
//         console.log(err);
//       },
//       staleTime: 0,
//     }
//   );
// };

// export const useDevicesDataQuery = () => {
//   return useQuery(
//     usersQueryKeys.devicesData(),
//     () =>
//       axiosInstance.get(`${API_URL}manage/devicesData`).then((res) => {
//         // console.log(res.data);
//         return res.data;
//       }),
//     {
//       onError: (err) => {
//         console.log(err);
//       },
//       staleTime: 0,
//     }
//   );
// };

// export const useDeviceDataIdQuery = ({ id }) => {
//   return useQuery(
//     usersQueryKeys.deviceData(id),
//     () =>
//       axiosInstance.get(`${API_URL}manage/deviceData/${id}`).then((res) => {
//         // console.log(res.data);
//         return res.data;
//       }),
//     {
//       onError: (err) => {
//         console.log(err);
//       },
//       staleTime: 0,
//     }
//   );
// };

// export const useDeviceLogsQuery = () => {
//   return useQuery(
//     usersQueryKeys.deviceLogs(),
//     () =>
//       axiosInstance.get(`${API_URL}manage/deviceLogs`).then((res) => {
//         // console.log(res.data);
//         return res.data;
//       }),
//     {
//       onError: (err) => {
//         console.log(err);
//       },
//       staleTime: 0,
//     }
//   );
// };

// export const useDeviceLogsIdQuery = ({ id }) => {
//   return useQuery(
//     usersQueryKeys.deviceLogsId(id),
//     () =>
//       axiosInstance.get(`${API_URL}manage/deviceLogs/${id}`).then((res) => {
//         // console.log(res.data);
//         return res.data;
//       }),
//     {
//       onError: (err) => {
//         console.log(err);
//       },
//       staleTime: 0,
//     }
//   );
// };
