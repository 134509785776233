import React from "react";

// styled components
import {
  BgContainerImage,
  FormBtn,
  FormBtnContainer,
  FormContentContainer,
  FormPageCountainer,
} from "../components/StyledComponents";

// background image
import background from "../assests/bg1.jpeg";

// form package
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// Form label and input box
import { FormElements } from "../components/FormElements";

// Company Logo
import MainLogo from "../components/MainLogo";

// Footer logo component
import FooterComponent from "../components/Footer";

// Login mutation
import { useLoginMutation } from "../services/auth.service";

// Status Code
import { StatusCodes } from "http-status-codes";
import { Toaster } from "react-hot-toast";

const Login = () => {
  const loginMutation = useLoginMutation({
    onSuccess: () => {
      formik.resetForm({ values: "" });

      // complete submission
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err.response) {
        if (err.response.status === StatusCodes.BAD_REQUEST) {
          const { message } = err.response.data;

          if (message.includes("empty")) {
            formik.setFieldError("email", message);
            formik.setFieldError("password", message);
          } else if (message.includes("Invalid")) {
            formik.setFieldError("email", message);
          } else if (message.includes("Password")) {
            formik.setFieldError("password", message);
          } else if (message.includes("Failed")) {
            formik.setFieldError("email", message);
            formik.setFieldError("password", message);
          }
        }
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
      password: Yup.string()
        .min(8, "Must be 8 characters or more")
        .required("Password is Required"),
    }),
    onSubmit: (values) => {
      loginMutation.mutate(values);
    },
  });

  return (
    <BgContainerImage background={background}>
      <FormPageCountainer>
        <FormContentContainer>
          <MainLogo />
          <div>
            <Toaster position="top-center" reverseOrder={false} />
          </div>

          <FormikProvider value={formik}>
            <Form>
              <FormElements
                id="email"
                label="Email"
                name="email"
                type="email"
                placeholder="Enter your email"
              />

              <FormElements
                id="password"
                label="Password"
                name="password"
                type="password"
                placeholder="Enter your password"
              />

              {!formik.isSubmitting && (
                <FormBtnContainer>
                  <FormBtn type="submit">Login</FormBtn>
                </FormBtnContainer>
              )}

              {formik.isSubmitting && (
                <FormBtnContainer>
                  <FormBtn type="submit">Login</FormBtn>
                </FormBtnContainer>
              )}
            </Form>
          </FormikProvider>
        </FormContentContainer>
        <FooterComponent />
      </FormPageCountainer>
    </BgContainerImage>
  );
};

export default Login;
