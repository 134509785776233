import { QueryClient, setLogger } from "react-query";

const noop = () => {};

setLogger({ log: noop, warn: noop, error: noop });
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // if it is a client error, do not retry
        if (
          error.response &&
          error.response.status &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          return false;
        }

        // otherwise, retry 1 times
        return failureCount < 2;
      },
    },
  },
});
