import React from "react";

// Styled Components
import { List, ItemsList, UserIconList, ItemsLink } from "./StyledComponents";

// Logout Mutation function
import { useLogoutMutation } from "../services/auth.service";

// import { useQueryClient } from "react-query";

const UserList = ({ role }) => {
  const logoutMutation = useLogoutMutation();
  // const queryClient = useQueryClient();
  return (
    <UserIconList>
      <List>
        {/* {role?.data.role === "customer" ? (
          <ItemsList>
            <ItemsLink to="/userProfile">Profile</ItemsLink>
          </ItemsList>
        ) : (
          <li></li>
        )} */}

        {/* <hr /> */}

        <ItemsList>
          <ItemsLink
            onClick={() => {
              logoutMutation.mutate();
            }}
          >
            Logout
          </ItemsLink>
        </ItemsList>
      </List>
    </UserIconList>
  );
};

export default UserList;
