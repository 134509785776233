import React, { useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
// JSPdf library
import jsPDF from "jspdf";
import "jspdf-autotable";
import { logoPdf } from "../assests/logoPdf";
import { logoWs } from "../assests/logoWs";

// Styled Components
import {
  ActionsContainer,
  BgContainer,
  CustButton,
  DHNameContainer,
  DashboardHeaderContainer,
  TableContainer,
  TableStyles,
} from "../components/StyledComponents";

// Date Picker Components
import DatePicker from "react-datepicker";

// Toast Notification
// import toast, { Toaster } from "react-hot-toast";

// Excel Export Component
import { CSVLink } from "react-csv";
import Navbar from "../components/Navbar";
import {
  useAlarmLogsHistoryByClientQuery,
  useProfileQuery,
} from "../services/users.service";
import TableComponent from "../components/TableComponent";

const ModeratorAlarmLogs = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const sDate = startDate.toISOString().slice(0, 10);
  const eDate = endDate.toISOString().slice(0, 10);

  const profileQuery = useProfileQuery();
  const alarmHistoryLogsQuery = useAlarmLogsHistoryByClientQuery(sDate, eDate);

  const columns = useMemo(
    () => [
      {
        Header: "No.",
        id: "index",
        accessor: (_row, i) => i + 1,
      },
      {
        Header: "Device ID",
        accessor: "lampNo",
      },
      {
        Header: "PN",
        accessor: "pn",
      },
      {
        Header: "Error Time",
        accessor: "errorTime",
      },
      {
        Header: "Warning Type",
        accessor: "errorType",
      },
      {
        Header: "Error End Time",
        accessor: "errorEndTime",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clientId = id;
  const clientAlarmData = alarmHistoryLogsQuery.data?.data.warnings.filter(
    (device) => {
      if (device.projectId === parseInt(clientId)) return device;
      return false;
    }
  );

  // console.log(clientAlarmData);
  // console.log(new Date(clientAlarmData[0]?.gts));

  const data = useMemo(
    () =>
      (clientAlarmData ?? []).map((device, i) => {
        return {
          index: i + 1,
          lampNo: device.lampNo,
          errorTime: new Date(device.gts).toLocaleString(),
          errorEndTime: new Date(device.cts).toLocaleString(),
          errorType: device.errorType,
          pn: device.pn,
        };
      }),
    [clientAlarmData]
  );

  // console.log(data);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(25);

    const title = "Comdelta Technologies Sdn Bhd.";
    const headers = [
      { header: "No.", dataKey: "index" },
      { header: "Device ID", dataKey: "lampNo" },
      { header: "PN", dataKey: "pn" },
      { header: "Error Time", dataKey: "errorTime" },
      { header: "Error Type", dataKey: "errorType" },
      { header: "Error End Time", dataKey: "errorEndTime" },
    ];
    // { header: "Signal Status", dataKey: "signal_status" },
    // { header: "Light", dataKey: "light_brightness" },
    let content = {
      startY: 135,
      columns: headers,
      body: data,
      styles: {
        halign: "center",
        cellPadding: { top: 10, right: 5, bottom: 10, left: 5 },
        fontSize: 12,
      },
    };

    const addFooterCopyRight = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();

      doc.setFont("helvetica", "italic", "bold");
      doc.setFontSize(8);
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(
          `Copyright © ${new Date().getFullYear()} Comdelta Technologies Sdn Bhd. All rights reserved.`,
          doc.internal.pageSize.width / 2,
          570,
          {
            align: "center",
          }
        );
      }
    };

    const addFooterPageNumber = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();

      doc.setFont("helvetica", "italic");
      doc.setFontSize(8);
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text("Page " + String(i) + " of " + String(pageCount), 40, 570, {
          align: "center",
        });
      }
    };

    clientId === 1587
      ? doc.addImage(logoWs, "PNG", 360, 15, 140, 80)
      : doc.addImage(logoPdf, "PNG", 360, 15, 140, 80);

    doc.text(title, marginLeft, 125);
    doc.autoTable(content);
    addFooterCopyRight(doc);
    addFooterPageNumber(doc);
    doc.save(`Alarm Logs .pdf`);
  };

  const headers = [
    { label: "No.", key: "index" },
    { label: "Device ID", key: "lampNo" },
    { label: "PN", key: "pn" },
    { label: "Error Time", key: "errorTime" },
    { label: "Error Type", key: "errorType" },
    { label: "Error End Time", key: "errorEndTime" },
  ];

  if (profileQuery.isLoading || alarmHistoryLogsQuery.isLoading) {
    return (
      <div className="absolute text-xl text-black top-1/2 left-1/2">
        <h1>Loading......</h1>
      </div>
    );
  }

  return (
    <BgContainer className="bg-white">
      <Navbar />
      <div className="flex flex-col flex-1 p-1 mx-2 mt-5">
        {/* <div>
        <Toaster position="top-center" reverseOrder={false} />
        </div> */}
        <DashboardHeaderContainer>
          <DHNameContainer>
            <h1>{profileQuery.data?.data.name}</h1>
            <h2>Project Name: {name}</h2>
          </DHNameContainer>
        </DashboardHeaderContainer>
        <TableContainer>
          <ActionsContainer align={"space-between"}>
            <div className="flex items-center justify-between w-auto px-2 py-2 ipSc:flex-col">
              <div>
                <CustButton color={"#a11f1f"} onClick={() => exportPDF()}>
                  Download Pdf
                </CustButton>
              </div>
              <div>
                <CustButton color={"#33cc00"}>
                  <CSVLink
                    className="text-white hover:text-black"
                    data={data ?? []}
                    headers={headers}
                    filename={`Alarm Logs .csv`}
                  >
                    Download Excel
                  </CSVLink>
                </CustButton>
              </div>
            </div>

            <div className="flex items-center justify-between w-1/2 ipSc:flex-col ">
              <div>
                <CustButton
                  color={"#005ce6"}
                  onClick={() => alarmHistoryLogsQuery.refetch()}
                >
                  Confirm Date
                </CustButton>
              </div>
              <div className="flex flex-row items-center justify-center lapSc:flex-col">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={endDate}
                />
                <h4 className="px-1 mx-1 text-center text-white">To</h4>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </div>
            </div>
          </ActionsContainer>

          <TableStyles>
            {data.length === 0 ? (
              <h1 className="text-center">No Data, select date to show data</h1>
            ) : (
              <TableComponent data={data} columns={columns} />
            )}
          </TableStyles>
        </TableContainer>
      </div>
    </BgContainer>
  );
};

export default ModeratorAlarmLogs;
